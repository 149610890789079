/**
 * Wrapper for the Javascript Date object to support 
 */


export function makeDateStr(date : Date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}
export class DateModel extends Date {

    constructor(date : String) {
        try {
            const [year, month, day] = date.split('-').map(x => parseInt(x));
            super(year, month - 1, day);
        }catch(err) {
            throw new Error('Invalid Date Format ' + date);
        }
    }
}



export class TimeModel extends Date {
    constructor(time : string) {
        try {
            const [hour, min, sec] = time.split(':').map(x => parseFloat(x));
            super(2020, 1, 1, hour, min, sec);
        }catch(err) {
            throw new Error('Invalid Date Format ' + time);
        }
    }
}

export function serializeTime(time : Date) {
    return time.getHours() + ':'  + time.getMinutes() + ':' + time.getSeconds();
}