import React, { useState } from 'react';
import { purpleColor } from '../../constants';
import ScaleLoader from "react-spinners/ScaleLoader";
import { CoreProvider } from '../../state/contexts/core-context';
import './core.css';

interface CoreState { loading: boolean; } 

function Core(props : any) {
    const [values, setValues] = useState<CoreState>({loading: false});

    const setLoading = (loading : boolean) : void => {
        setValues({...values, loading: loading});
    }


    const renderLoader = () => {
        if(values.loading)
            return (
                <div className="spinner-container"> 
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        
        return (null);
    }


    return (
        
            <div className="content-container">
                { renderLoader() }
                <CoreProvider value={{setLoading: setLoading}}>
                    { props.children }
                </CoreProvider>
            </div>
    );
}
  
export default Core;