import { AuthState } from '../types/auth.type';
import { AuthAction, AuthActionOption } from '../actions/auth.actions';

const initialState : AuthState = {
    token: ''
}
const reducer = (state : AuthState = initialState, action: AuthAction) : AuthState => {
    switch(action.type) {
        case AuthActionOption.STORE_TOKEN: 
            return {...state, token: action.payload};
        case AuthActionOption.REMOVE_TOKEN:
            return { ...state, token: ''};
        default:
            return state;
    }
}

export default reducer;