import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { ClassesEmail } from '../models/emails';


setAuthOnAxios(Axios);

const ComposeEmailService = {
    async sendEmailToClasses(email : ClassesEmail) : Promise<void> {
        var url = settings.baseUrl + 'api/reports/compose-email';
        const body = email.serialize();
        console.log(body)
        try {
            await Axios.post(url, body);
        }
        catch(err) {
            throw err.response?.data;
        }
    }
}

export default ComposeEmailService;