import React from 'react';
import './App.css';
import '@fontsource/roboto';
import LoginPage  from './authentication/login/login';
import PortalPage from './portal/portal';
import DashboardPage from './portal/core/dashboard/dashboard';
import ArchivePage from './portal/core/archive/archive';
import StudentsPage from './portal/core/students/students';
import ClassesPage from './portal/core/classes/classes';
import RankingPage from './portal/core/ranking/ranking';
import SendNotificationPage from './portal/core/send-notification/send-notification';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ResetPasswordPage from './authentication/reset-password/reset/reset-password';
import TeachersPage from './portal/core/teachers/teachers';
import ECRPage from './portal/core/ecr/ecr';
import EsrPage from './portal/core/esr/esr';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ComposeEmailPage from './portal/core/compose-email/compose-email';
import StudentsPerformancePage from './portal/core/students-performance/students-performance';

const isAuthenticated = () => localStorage.getItem('token') !== null;

/***********************************************
 * Router switch component that encapsulates 
 * the portal. It displays the page according to the 
 * url if the user is authenticated or redirects to 
 * the login page otherwise
 **********************************************/
function LoadPortalModule()
{
    return (
      <PortalPage>
        <Switch>
          <Route exact path='/' component={DashboardPage}/>
          <Route path='/archive/:cat' component={ArchivePage}/>
          <Route exact path='/students' component={StudentsPage}/>
          <Route exact path='/classes' component={ClassesPage}/>
          <Route exact path='/teachers' component={TeachersPage}/>
          <Route exact path='/ecr' component={ECRPage}/>
          <Route exact path='/esr' component={EsrPage}/>
          <Route exact path='/ranking' component={RankingPage}/>
          <Route exact path='/compose' component={ComposeEmailPage}/>
          <Route exact path='/send-notification' component={SendNotificationPage}/>
          <Route exact path='/performance' component={StudentsPerformancePage} />
          <Route path='*' render={()=> <Redirect to="/"/>}/>
        </Switch>
      </PortalPage>
    )
}

function LoadAuthModule() {
    return (
      <Switch>
          <Route exact path='/' component={LoginPage}/>
          <Route path='/reset-password/:userId/:token' component={ResetPasswordPage}/>
          <Route path='*' render={()=> <Redirect to="/"/>}/>
      </Switch>
    )
}

/*******************
 *  Main Component
 ******************/
function App() {
  //localStorage.clear();

  
  /************************************************
   * Helper functions for rendering the components
   ************************************************/
  const renderLoginPage = () => isAuthenticated() ? (<LoadPortalModule/>) : (<LoadAuthModule/>);


  /* Main Rendering part*/
  return (
    <Router>
      <div>
        <ReactNotification />
        { renderLoginPage() }
      </div>
    </Router>
  );
}

export default App;
