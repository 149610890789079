import React from 'react';
import './modal.component.css';

interface ModalProps{
    isOpen : boolean;
    closeModal : () => void;
    children : any;
}
export default function ModalComponent(props : ModalProps) {
    if(!props.isOpen)
        return (null);

    return (
        <div>
            <div className="modal-container">
                <div className="exit-div" onClick={props.closeModal}></div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>

        </div>
    )
}