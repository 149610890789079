import React from 'react';
import './email-template.css';

interface ComposeEmailTemplateProps {
    children : any;
}
export default function ComposeEmailTemplate(props : ComposeEmailTemplateProps) {
    return (
        <div>
            <div className="compose-email-template-container">
                <div className="compose-email-template-header">
                    <img className = "compose-email-template-logo" alt="logo" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/header.png?alt=media&amp;token=ebd2af4b-fa08-4f89-b4f5-63b792d92268" height="85%"/>
                </div>
                <div className="compose-email-template-body">
                    <div dangerouslySetInnerHTML={{ __html: props.children }} />
                </div>
            </div>
            <div className="compose-email-template-footer">
            </div>
        </div>
    );
}