import { ClassModel } from "./classes";


export class ClassesEmail {
    classes : Set<ClassModel> = new Set();
    subject : string = '';
    body : string = '';

    serialize() {
        const arr : number[] = [];
        this.classes.forEach(c => arr.push(c.id));
        return {classes: arr, subject: this.subject, body: this.body};
    }
}