import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { ClassNotification } from '../models/notification';

setAuthOnAxios(Axios);

const SendNotificationService = {
    async sendNotification(notification : ClassNotification) : Promise<void> {
        const url = settings.baseUrl + 'api/notifications/send'
        try {
            await Axios.post(url, notification.serialize());
        }
        catch(err) {
            console.log(err.response)
            throw err.response.data
        }
    }
}

export default SendNotificationService;