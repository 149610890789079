import { Button, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, {useState} from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import './reset-password.css';
import { purpleColor } from '../../../constants';
import { BeatLoader } from 'react-spinners';
import { logAllErrors, logSuccess } from '../../../utils/utils';
import AuthService from '../../../services/auth.service';

interface Props {
    token : string;
    userId : string;
}
interface PasswordState {
    password : string;
    confirmPassword : string;
}
const useStyles = makeStyles({
    submitButton: {
        background: '#ca0000',
        color: 'white',
        width: '50%',
        height: '7vh',
        '&:hover': {
            background: '#e53042',
        }
    }
});

function ResetPasswordPage({ match }: RouteComponentProps<Props>) {
    

    const classes = useStyles();

    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [password, setPassword] = useState<PasswordState>({
        password: '',
        confirmPassword: ''
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (key : keyof PasswordState) => (event : React.ChangeEvent<HTMLInputElement>) => {
        setPassword({...password, [key]: event.target.value});
    }
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const userId = parseInt(match.params.userId)
    if(!userId) {
        logAllErrors('Invalid Reset Password URL');
        return (<Redirect to="/"/>);   
    }

    const onResetPassword = () => {
        if(password.password === '' || password.password !== password.confirmPassword) {
            logAllErrors('Both Passwords do not match!');
            return;
        }

        setLoading(true);
        AuthService.resetPassword(match.params.token, userId, password.password).then(() => {
            logSuccess('Your Password was changed! You can use it to login to the App');
            setPasswordChanged(true);
        }).catch((err) => logAllErrors(err))
        .finally(() => setLoading(false));
    }

    ///////////////////////////////////
    // HTML
    ///////////////////////////////////
    /**** When Password Change is successful****/
    if(passwordChanged){
        return (
            <div className="Login-container">
                <div className="Login-box">
                    <div className="Box-left-section">
                        <h2 className="success-title">Password Changed Successfully</h2>
                    </div>
                    <div className="Box-right-section">
                        <img className="logo-img" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/logo-inverse.png?alt=media&token=91355777-cc1c-4277-a591-b13405d81c85" alt="logo"/>
                    </div>
                </div>
            </div>
        )
    }


    /****Main Render*****/
    return (
        <div className="Login-container">
            <div className="Login-box">
                <div className="Box-left-section">
                    <h2 className="title">Change Password</h2>
                    <h4 className="prompt">Please type in your new password below</h4>
                    <div className="Textboxes">
                    <div className="Password-textbox">
                            <TextField
                                error={password.confirmPassword !== password.password? true : false}
                                variant="outlined"
                                label="New Password"
                                type={showPassword ? 'text' : 'password'}
                                value={password.password}
                                onChange={handleChange('password')}
                                size="medium"
                                fullWidth
                                InputProps={{
                                    endAdornment: 
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </div>
                        <div className="Password-textbox">
                            <TextField
                                error={password.confirmPassword !== password.password? true : false}
                                variant="outlined"
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                value={password.confirmPassword}
                                onChange={handleChange('confirmPassword')}
                                size="medium"
                                fullWidth
                                InputProps={{
                                    endAdornment: 
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </div>
                        <div className="Submit-button-div">
                            <Button variant="contained" size="large" color="inherit" className="Submit-button" classes={{root: classes.submitButton}} onClick={onResetPassword}>
                                Change
                            </Button>
                        </div>
                        <BeatLoader color={purpleColor} loading={loading}/>
                    </div>
                </div>
                <div className="Box-right-section">
                        <img className="logo-img" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/logo-inverse.png?alt=media&token=91355777-cc1c-4277-a591-b13405d81c85" alt="logo"/>
                </div>
            </div>
        </div>
        );
}

export default ResetPasswordPage;