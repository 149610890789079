import React, {useState} from 'react';
import './create-class.css';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SaveIcon from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import { ClassCategoryModel, ClassModel, nilClass } from '../../../../models/classes';
import { UserModel } from '../../../../models/users';
import {purpleColor} from '../../../../constants';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ClassInfoComponent from '../component/class-info/class-info';
import SessionsComponent from '../component/sessions/sessions';
import TeachersComponent from '../component/teachers/teachers';
import ClassService from '../../../../services/classes.services';
import { logAllErrors, logSuccess } from '../../../../utils/utils';
import OutlinedSelect from '../../../../basic-components/outlined-select/outlined-select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);


interface CreatePageProps {
    title : string;
    onSave : (_ : ClassModel) => void;
    classCategories : Array<ClassCategoryModel>;
    teachers : Array<UserModel>;
    onClose : () => void;
};

function CreateClassPage(props : CreatePageProps) {
    const classes = useStyles();
    const [classType, setClassType] = useState<'School'|'Center'>('Center');
    const [class_, setClass] = useState<ClassModel>({
        id: 0,
        className: '',
        classCategory: props.classCategories[0],
        students: [],
        teachers: [],
        fees: undefined,
        sessions: [],
        startDate: new Date(),
        endDate: new Date(),
        lastSessionDate: nilClass.lastSessionDate,
        isRanked: true
    });




    /******Render Helpers************/
    const [loading, setLoading] = useState<boolean>(false);
    const renderLoader = () => {
        if(loading)
            return (
                <div className="spinner-container"> 
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        return (null);
    }

    /**Button Clicks Handler**/
    const onSave = () => {
        setLoading(true);

        const serviceFunc = classType === 'School' ? ClassService.createSchoolClass : ClassService.createCenterClass;
        serviceFunc(class_).then((class_) => {

            props.onSave(class_);
            props.onClose();
            logSuccess('Class Created Successfully');
            
        }).catch(err => {
            console.log(err);
            logAllErrors(err);
        }).finally(() => setLoading(false));
    }


    /******Render******/
    return (
        <div className="student-page-container">
            <div className="student-edit-page-title">
                <h2>{props.title}</h2>
            </div>
            <div className="form-container">
                <OutlinedSelect items={['School', 'Center']} label='Class Type' optional={false} value={classType} onChange={(e) => {setClassType(e?.target?.value as 'School' | 'Center' || classType)}}/>
                <ClassInfoComponent class_={class_} setClass={setClass} classCategories={props.classCategories} />
            </div>
            <div className="form-container">
                <SessionsComponent class_={class_} setClass={setClass}/>
            </div>
            <div className="form-container">
                <TeachersComponent allTeachers={props.teachers} class_={class_} setClass={setClass} />
            </div>
            <div className="edit-student-submit">
                <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={onSave}
                >
                    Save
                </Button>
                <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    startIcon={<Close />}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </div>
            {renderLoader()}
        </div>
    )
}

export default CreateClassPage;