import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { ClassCategoryModel, ClassModel, serializeClass } from '../models/classes';
import { serializeUser, StudentModel } from '../models/users';


setAuthOnAxios(Axios);

const ClassesService = {
    async fetchAllClasses(class_category? : string) : Promise<Array<ClassModel>> {
        var url = settings.baseUrl + 'api/class/all';
        if(class_category)
            url += '?class_category=' + class_category;
        console.log(url);
        var res = null;
        try {
            res = await Axios.get(url)
        }
        catch(err) {
            throw err.response?.data;
        }
        try {
            return res?.data.map((obj : any)=> new ClassModel(obj))
        } catch(err) {
            console.log(err);
            throw new Error('Unexpected Class Format');
        }
    },
    async fetchAllClassCategories() : Promise<Array<ClassCategoryModel>> {
        const url = settings.baseUrl + 'api/class/class-category/all';
        var res = null;
        try {
            res = await Axios.get(url);
        } catch(err) {
            throw err.response?.data;
        }

        try {
            return res?.data.map((obj : any) => new ClassCategoryModel(obj['name']));
        }
        catch(err) {
            console.log(err);
            throw new Error('Unexpected Class Format Error');
        }
    },
    async createCenterClass(class_ : ClassModel) : Promise<ClassModel> {
        const url = settings.baseUrl + 'api/class/center-class';
        try{ 
            const res = await Axios.post(url, serializeClass(class_));
            res.data.teachers = class_.teachers.map(t => serializeUser(t));
            return new ClassModel(res?.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    async createSchoolClass(class_ : ClassModel) : Promise<ClassModel> {
        const url = settings.baseUrl + 'api/class/school-class';
        try{ 
            const res = await Axios.post(url, serializeClass(class_));
            res.data.teachers = class_.teachers.map(t => serializeUser(t));
            return new ClassModel(res?.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    async updateClass(class_ : ClassModel) : Promise<ClassModel> {
        const url = settings.baseUrl + 'api/class/generic/' + class_.id;
        try {
            await Axios.put(url, serializeClass(class_))
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
        return class_;
    },
    async deleteClass(class_ : ClassModel) {
        const url = settings.baseUrl + 'api/class/generic/' + class_.id;
        try {
            await Axios.delete(url);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    async enrollStudent(class_: ClassModel, student : StudentModel) {
        const url = settings.baseUrl + 'api/class/enroll/' + class_.id + '/' + student.authUser.id;
        try {
            await Axios.post(url);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
        return class_;
    },
    async unenrollStudent(class_ : ClassModel, student : StudentModel) {

        const url = settings.baseUrl + 'api/class/enroll/' + class_.id + '/' + student.authUser.id;
        try {
            await Axios.delete(url);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }

        return class_;
    } 
};

export default ClassesService;