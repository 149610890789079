import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { ECRModel } from '../models/ecr';

setAuthOnAxios(Axios);


const ECRService = {
    async sendReports(ecr : ECRModel) : Promise<void>{
        const url = settings.baseUrl + 'api/reports/send-ecr';
        try {
            console.log(ecr.serialize());
            await Axios.post(url, ecr.serialize());
        } catch(err) {
            console.log(err);
            throw err.response?.data;
        }
    }
};
export default ECRService;