import settings from '../settings.json';
import Axios from 'axios';
 import { GenderModel } from '../models/gender';
import { SchoolModel } from '../models/school';
/**
 * Service that fetches the Options and other data from the backend
 */

 const OptionsService = {

    fetchAllGenders: async function() {
        const url =  settings.baseUrl + 'api/user/genders/all';
        const res = await Axios.get(url);
        return res?.data.map((gender : any) => new GenderModel(gender.gender));
    },
    fetchAllSchools: async function() {
        const url =  settings.baseUrl + 'api/user/schools/all';
        const res = await Axios.get(url);
        return res?.data.map((school : any) => new SchoolModel(school.name));
    },
}

export default OptionsService;