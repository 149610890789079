import { Delete, Person } from '@mui/icons-material';
import React from 'react';
import { ClassModel } from '../../../../../models/classes';
import { UserModel } from '../../../../../models/users';
import OutlinedAutoComplete from '../../../../../basic-components/outlined-autocomplete/outlined-autocomplete';
import './teachers.css'

interface TeachersProps {
    allTeachers : Array<UserModel>;
    class_ : ClassModel;
    setClass : (_ : ClassModel) => void;
}

function TeachersComponent(props : TeachersProps) {

    
    const onDeleteTeacher = (teacher : UserModel) => {
        props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, teachers: props.class_.teachers.filter(t => t.authUser.id !== teacher.authUser.id)});
    }
    const addNewTeacher = (teacher : UserModel | null) => {
        if(!teacher) return;
        if(props.class_.teachers.find((t) => t.authUser.id === teacher.authUser.id))
            return;
        props.class_.teachers.push(teacher);
        props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, teachers: props.class_.teachers});
    }
    /******Render Helpers********/
    const renderUser = (user : UserModel, index : number, onDeleteUser : (user : UserModel) => void) => {
        return (
            <tr key={"tr"+index}>
                <td>{ index + 1 }</td>
                <td>{ user.firstName + ' ' + user.lastName }</td>
                <td>{ user.nickName || '___' }</td>
                <td>{ user.authUser.email }</td>
                <td>{ user.birthdate.toLocaleDateString()}</td>
            <td onClick={(e) => { e.stopPropagation(); onDeleteUser(user)}}><Delete color="secondary"/></td>
            </tr>
            )
    }
    const renderUsers = (users : Array<UserModel>, onDeleteUser : (user : UserModel) => void) => {
       return users.map((teacher, index) => renderUser(teacher, index, onDeleteUser));
    }

    /**********Render***********/
    return (
        <div className="teachers-container">
            <h3>Teachers</h3>
                <table className="teachers-table">
                    <thead>
                        <tr>
                            <th><Person/></th>
                            <th>Name</th>
                            <th>Nickname</th>
                            <th>Email</th>
                            <th>Birthdate</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderUsers(props.class_.teachers, onDeleteTeacher)}
                    </tbody>
                </table>
                <div className="choose-user">
                    <OutlinedAutoComplete allUsers={props.allTeachers} onUserSelected={addNewTeacher}/>
                </div>
        </div>
    )
}

export default TeachersComponent;