import { ClassModel, nilClass } from "./classes";
import { makeDateStr } from "./Date";


export class ECRModel {
    class_ : ClassModel;
    date : Date;
    body : string;

    constructor(class_ : ClassModel, date : Date | undefined, body : string) {
        this.class_ = class_;
        this.date = date || class_.lastSessionDate();
        this.body = body;
    }

    serialize() {
        return {
            myclass: this.class_.id,
            date: makeDateStr(this.date),
            body: this.body
        }
    }
}

export const NilECR = new ECRModel(nilClass, new Date(), '');