import React, {useState} from 'react';
import { AuthUserModel, StudentParentModel, UserModel } from '../../../../models/users';
import './edit-teacher.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GenderModel } from '../../../../models/gender';
import OutlinedSelect from '../../../../basic-components/outlined-select/outlined-select';
import OutlinedDatePicker from '../../../../basic-components/outlined-datepicker/outlined-datepicker';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SaveIcon from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import { logAllErrors, logSuccess } from '../../../../utils/utils';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {purpleColor} from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);

interface EditPageProps {
    title: string,
    teacher : UserModel;
    onSave : (_ : UserModel) => void;
    genders : Array<GenderModel>;
    onClose : () => void;
    serviceFunc : (_ : UserModel) => Promise<UserModel>;
}; 
export default function EditStudentPage(props : EditPageProps) {
    /******Loading Animation********/
    const [loading, setLoading] = useState<boolean>(false);
    const renderLoader = () => {
        if(loading)
            return (
                <div className="spinner-container"> 
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        return (null);
    }



    /************User Management***************/
    const classes = useStyles();
    const [teacher, setTeacher] = useState<UserModel>(props.teacher);
    const setRequiredField = (key : keyof UserModel, value : string | Date | AuthUserModel) => {
        setTeacher({...teacher, [key]: value});
    }
    const setOptionalField = (key : keyof UserModel, value? : string | StudentParentModel) => {
        if(value === undefined || value === '') 
            setTeacher({...teacher, [key]: undefined});
        else
            setTeacher({...teacher, [key]: value});
    }

    const validate = () => {
        return true;
    }
    const onSave = async () => {
        if(!validate()) return;
        setLoading(true);
        props.serviceFunc(teacher).then(_ => {
            logSuccess('Student Update Sucessfully');
            props.onSave(teacher);
        }).catch(err => {
            logAllErrors(err);
            console.error(err);
        }).finally(() => setLoading(false));
    }

    return (
        <div className="student-page-container">
            <div className="student-edit-page-title">
                <h2>{props.title}</h2>
            </div>
            <div className="form-container">
                <h3>Personal Info</h3>
                <TextField error={teacher.firstName === ''} id="outlined-basic" label="First Name" variant="outlined" value={teacher.firstName} onChange={(event) => { setRequiredField('firstName', event.target.value || '')}} fullWidth/>
                <TextField error={teacher.lastName === ''} id="outlined-basic" label="Last Name" variant="outlined"  value={teacher.lastName} onChange={(event) => { setRequiredField('lastName', event.target.value || '')}} fullWidth/>
                <TextField id="outlined-basic" label="Nickname" variant="outlined"  value={teacher.nickName || ''} onChange={(event) => { setOptionalField('nickName', event.target.value || '')}} fullWidth/>
                <OutlinedSelect value={teacher.gender} optional={false} label="Gender" items={props.genders.map(gender => gender.gender)} onChange={(event) => { setRequiredField('gender', event.target.value as string || '')} }/>
                <OutlinedDatePicker value={teacher.birthdate} label="Birthdate" onChange={(event) => { setRequiredField('birthdate', event || teacher.birthdate)}}/>
            </div>
            <div className="form-container">
                <h3>Contacts Info</h3>
                <TextField  error={teacher.authUser.email === ''} id="outlined-basic" label="Email" variant="outlined" value={teacher.authUser.email}  onChange={(event) => { setRequiredField('authUser', {email: event.target.value || '', id: teacher.authUser.id})} } fullWidth/>
                <TextField id="outlined-basic" label="Phone Number" variant="outlined"  value={teacher.phoneNumber || ''} onChange={(event) => { setOptionalField('phoneNumber', event.target.value || '')} } fullWidth/>
            </div>

            <div className="edit-student-submit">
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={onSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    startIcon={<Close />}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </div>
            {renderLoader()}
        </div>
    
    )
}