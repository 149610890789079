import { Button, List, ListItem, makeStyles } from '@material-ui/core';
import React, {useState} from 'react';
import {ArchiveRecordModel } from '../../../../../models/archive';
import { StudentModel } from '../../../../../models/users';
import {EntityModel} from '../../../../../models/archive';
import ArchiveRecordInputProps from '../../../../../basic-components/archive-record-input/props-interface';
import './records-list.css';
import { Check, ClearAll } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

interface RecordsListProps {
    students : Array<StudentModel>;
    recordFactory : ArchiveRecordModel;
    entity : EntityModel<ArchiveRecordModel>;
    recordInputComponent : (_ : ArchiveRecordInputProps) => React.ReactElement;
    onRecordUpdate : (_ : EntityModel<ArchiveRecordModel>) => void;
}

export default function RecordsListComponent(props : RecordsListProps) {
    /***********Component State**************/
    const classes = useStyles();

    const initStudentsIdToRecords = () => {
        const studentsIdToRecords = new Map<number, ArchiveRecordModel>();
        props.entity.records.forEach(record => studentsIdToRecords.set(record.student, record));
        return studentsIdToRecords
    }

    /***Initialize empty records for studest whose records are not ready ***/
    const [allRecords, setAllRecords] = useState<Array<ArchiveRecordModel>>();
    if(!allRecords) {
        const studentsIdToRecords = initStudentsIdToRecords();
        setAllRecords(props.students.map((student) => 
        studentsIdToRecords.get(student.authUser.id) || props.recordFactory.create_same_type_obj({student: student.authUser.id})));
    }


    /****Initialize Students Map id -> StudentModel*****/
    const [idToStudent, setIdToStudent] = useState<Map<number, StudentModel>>();
    if(!idToStudent) {
        const tmpMap = new Map<number, StudentModel>();
        for(var student of props.students)
            tmpMap.set(student.authUser.id, student);
        setIdToStudent(tmpMap);
    }
    const updateEntityInParent = (entity : EntityModel<ArchiveRecordModel>) => {
        entity.dirty = true;
        entity.records = entity.records.filter(record => record.isDefinedVal());
        props.onRecordUpdate(entity);
    }
    const onRecordvalueChange = (record: ArchiveRecordModel) => {
        const newRecords : Array<ArchiveRecordModel> = allRecords?.map((curRecord, _) => curRecord.student === record.student ? record : curRecord) || [];
        setAllRecords(newRecords);
        const entity = props.entity;
        entity.records = newRecords;
        updateEntityInParent(entity);
    }
    /***********Render Functions*************/
    const renderSingleRecord = (record : ArchiveRecordModel, index : number) => {
        const student = idToStudent?.get(record.student)
        if(!student) return (null);
        return (
            <ListItem button key={props.entity.name + record.student + '' + index}>
                <div className="student-record-list-order">{ index + 1}.</div>
                <div className="student-record-info">
                    {student.firstName + ' ' + student.lastName}
                </div>
                <div className="single-record-container">
                    { props.recordInputComponent({
                        record: record,
                        setRecordValue: onRecordvalueChange
                    }) }
                </div>
            </ListItem>
        )
    } 
    const renderRecordsList = () => {
        return allRecords?.map(renderSingleRecord);
    }

    const setAllRecordsWithVal = (fun : (_ : ArchiveRecordModel) => void) => {
        const entity = props.entity;
        allRecords?.forEach(fun);
        setAllRecords(allRecords);
        entity.records = allRecords || entity.records;
        updateEntityInParent(entity);
    }
    const onAllRecordsSetDefault = () => {
        setAllRecordsWithVal((rec) => rec.setRecordValue(true, rec.maxVal()));
    }
    const onAllRecordsClear = () => {
        setAllRecordsWithVal((rec) => rec.setRecordValue(undefined, undefined));
    }

    return (
        <div>
            <Button onClick={(e) => { e.stopPropagation(); onAllRecordsSetDefault(); }}>
                <Check color="primary"/> Fill All
            </Button>
            <Button onClick={(e) => { e.stopPropagation(); onAllRecordsClear(); }}>
                <ClearAll color="secondary"/>Clear All
            </Button>
            <List component="div" className={classes.nested} disablePadding>
            {renderRecordsList()}
            </List>
        </div>
    )
}