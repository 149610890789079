import { Delete, Person, Loop } from '@mui/icons-material';
import React, {useState} from 'react';
import { ScaleLoader } from 'react-spinners';
import { purpleColor } from '../../../../../constants';
import { ClassModel } from '../../../../../models/classes';
import { UserModel } from '../../../../../models/users';
import OutlinedAutoComplete from '../../../../../basic-components/outlined-autocomplete/outlined-autocomplete';
import ClassService from '../../../../../services/classes.services';
import './students.css'
import { logAllErrors, logSuccess } from '../../../../../utils/utils';

interface StudentsProps {
    allStudents : Array<UserModel>;
    class_ : ClassModel;
    setClass : (_ : ClassModel) => void;
    setGlobalClass : (_ : ClassModel) => void;
}

function StudentsComponent(props : StudentsProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const addNewStudent = (student : UserModel | null) => {
        if(!student) return;
        
        if(!window.confirm('Are you sure you want to enroll ' + student.firstName + ' ' + student.lastName + ' to the class')){
            return;
        }
        setLoading(true);
        ClassService.enrollStudent(props.class_, student).then(() => {
            if(props.class_.students.find(s => s.authUser.id === student.authUser.id))
                props.class_.students.push(student);
            props.setGlobalClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, students: props.class_.students});
            props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, students: props.class_.students});
            logSuccess('Enrollment Email was sent to the student');
        }).catch(err => {
            console.log(err);
            logAllErrors(err);
        }).finally(() => setLoading(false));
        
    }

    const onDeleteStudent = (student : UserModel) => {
        if(!window.confirm('Are you sure you want to Unenroll ' + student.firstName + ' ' + student.lastName + ' from the class')){
            return;
        }
        setLoading(true);
        ClassService.unenrollStudent(props.class_, student).then(() => {
            props.setGlobalClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, students: props.class_.students.filter(s => s.authUser.id !== student.authUser.id)});
            props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, students: props.class_.students.filter(s => s.authUser.id !== student.authUser.id)});
            logSuccess('Student is no longer enrolled in this class!');
        }).catch(err => {
            console.error(err);
            logAllErrors('Could not unenroll the student. Maybe try refreshing the page!')
        }).finally(() => setLoading(false));

    }

    /******Render Helpers********/
    const renderLoader = () => {
        if(loading)
            return (
                <div className="spinner-container"> 
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        return (null);
    }
    const renderUser = (user : UserModel, index : number, onDeleteUser : (user : UserModel) => void) => {
        return (
            <tr key={"tr"+index}>
                <td>{ index + 1 }</td>
                <td>{ user.firstName + ' ' + user.lastName }</td>
                <td>{ user.nickName || '___' }</td>
                <td>{ user.authUser.email }</td>
                <td>{ user.birthdate.toLocaleDateString()}</td>
                <td onClick={(e) => { e.stopPropagation(); addNewStudent(user)}}><Loop color="primary"/></td>
                <td onClick={(e) => { e.stopPropagation(); onDeleteUser(user)}}><Delete color="secondary"/></td>
            </tr>
            )
    }
    const renderUsers = (users : Array<UserModel>, onDeleteUser : (user : UserModel) => void) => {
       return users.map((teacher, index) => renderUser(teacher, index, onDeleteUser));
    }

    /**********Render***********/
    return (
        <div className="students-component-container">
            <h3>Enrolled Students</h3>
            <table className="students-table">
                <thead>
                    <tr>
                        <th><Person/></th>
                        <th>Name</th>
                        <th>Nickname</th>
                        <th>Email</th>
                        <th>Birthdate</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {renderUsers(props.class_.students, onDeleteStudent)}
                </tbody>
            </table>
            <div className="choose-user-student">
                <OutlinedAutoComplete allUsers={props.allStudents} onUserSelected={addNewStudent}/>
            </div>
            {renderLoader()}
        </div>

    );
}

export default StudentsComponent;