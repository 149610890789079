import {SvgIconComponent} from '@mui/icons-material';

/**
 *  A class that represents a side navigation bar item. Every item can have subitems  of 
 *  the same type and each item must have an icon. Use for rendering the navigation bar items
 */

export class NavBarItemModel {
    readonly icon : SvgIconComponent;
    readonly text : string;
    readonly subItems : Array<NavBarItemModel>;
    readonly link : string;

    constructor(icon : SvgIconComponent, text : string,  link : string, subItems? : Array<NavBarItemModel>) {
        this.icon = icon;
        this.text = text;
        this.subItems = subItems || [];
        this.link = link;
    }

    addSubItem(item : NavBarItemModel) {
        this.subItems.push(item);
    }

}