/***
 * Models for Student Rankings
 *
 */

import { checkAllRequiredFields } from "../utils/utils";
import { StudentModel } from "./users";

export class StudentRankModel {
    readonly student : StudentModel;
    readonly rank : number;
    readonly score : number;

    constructor(obj : any) {
        checkAllRequiredFields(obj, ['student', 'rank', 'score']);
        this.student = new StudentModel(obj['student']);
        this.rank = obj['rank'];
        this.score = obj['score'];

    }
}
export class RankingModel {
    readonly class_id : number;
    readonly rankings : Array<StudentRankModel>;
    constructor(obj : any) {
        checkAllRequiredFields(obj, ['class_', 'rankings']);
        this.class_id = obj['class_'];
        this.rankings = obj['rankings'].map((r : any) => new StudentRankModel(r));
    }
}

export const nilRankingModel = new RankingModel({
    class_: 0,
    rankings: []
});