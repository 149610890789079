import { checkAllRequiredFields, mod, reduce } from "../utils/utils"
import { DateModel, makeDateStr, serializeTime, TimeModel } from "./Date"
import { StudentModel, UserModel } from "./users"

export class SessionModel {
    day : number;
    time : Date;

    constructor(obj : any) {
        checkAllRequiredFields(obj, ['day', 'time']);
        this.day = obj['day'];
        this.time = new TimeModel(obj['time']);
    }
}

export class ClassCategoryModel {
    name : string;
    constructor(name : string) {
        this.name = name;
    }
}

export class ClassModel {
    id : number;
    sessions : Array<SessionModel> = [];
    classCategory : ClassCategoryModel;
    teachers : Array<UserModel> = [];
    students: Array<StudentModel> = [];
    className : string;
    fees? : number;
    startDate : DateModel;
    endDate : DateModel;
    isRanked: boolean;

    constructor(obj : any) {
        checkAllRequiredFields(obj, ['id', 'sessions', 'class_category', 'teachers', 'students', 'class_name', 'start_date', 'end_date', 'is_ranked']);
        this.id = obj['id'];
        this.sessions = obj['sessions'].map((session : any) => new SessionModel(session));
        this.classCategory = new ClassCategoryModel(obj['class_category']);
        this.teachers = obj['teachers'].map((teacher : any) => new UserModel(teacher));
        this.students = obj['students'].map((student : any) => new StudentModel(student));
        this.className = obj['class_name'];
        this.fees = obj['fees'];
        this.startDate = new DateModel(obj['start_date']);
        this.endDate = new DateModel(obj['end_date']);
        this.isRanked = obj['is_ranked']

        // post initialization code
        this.students.sort((s1, s2) => s1.firstName + s1.lastName < s2.firstName + s2.lastName ? -1 : 1)
    }

    lastSessionDate() : Date {
        if(this.sessions.length === 0) 
                return new Date();
        const date = new Date();
        const isDayInSessionsDays = (day : number) => {
            return reduce<SessionModel, boolean>(this.sessions, false, (acc, session) => acc || session.day === mod(day - 1, 7))
        }
        while(!isDayInSessionsDays(date.getDay()) && date >= this.startDate) {
            date.setDate(date.getDate() - 1);
        }
        if(!isDayInSessionsDays(date.getDay()))
            return new Date();
        return date;
    }
}


function serializeSession(session : SessionModel) {
    return {
        day: session.day,
        time: serializeTime(session.time)
    }
}
export function serializeClass(class_ : ClassModel) {
    return {
        id : class_.id,
        sessions: class_.sessions.map(serializeSession),
        class_category: class_.classCategory.name,
        class_name: class_.className,
        teachers: class_.teachers.map(t => t.authUser.id),
        fees: class_.fees,
        start_date : makeDateStr(class_.startDate),
        end_date : makeDateStr(class_.endDate)
    }
}


export const nilClass : ClassModel = new ClassModel({
    id : -1,
    sessions: [],
    class_category: new ClassCategoryModel(''),
    teachers: [],
    students: [],
    class_name: '',
    start_date: makeDateStr(new Date()),
    end_date: makeDateStr(new Date()),
    is_ranked: true
});