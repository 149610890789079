export enum AuthActionOption {
    STORE_TOKEN = 'STORE_TOKEN',
    REMOVE_TOKEN = 'REMOVE_TOKEN'
}

export interface StoreTokenAction {
    type :  AuthActionOption.STORE_TOKEN;
    payload : string;
}
export interface RemoveTokenAction {
    type : AuthActionOption.REMOVE_TOKEN;
}
export type AuthAction = RemoveTokenAction | StoreTokenAction 

