import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { UserModel } from '../../models/users';
import Person from '@mui/icons-material/Person';

const useStyles = makeStyles({
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
});

interface OutlinedAutoCompleteProps {
    allUsers : Array<UserModel>
    onUserSelected : (user : UserModel | null) => void;
};

function OutlinedAutoComplete(props : OutlinedAutoCompleteProps) {

  const classes = useStyles();
  const [value, setValue] = useState<string>('');
  const handleEnterClicked = (e : any) => {
    if(e.keyCode === 13) {
      setValue('');
    }
  }
  return (
    <Autocomplete
      id="user-select"
      options={props.allUsers as UserModel[]}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option : UserModel) => option.authUser.email + option.firstName + option.lastName}
      renderOption={(option : UserModel) => (
        <React.Fragment>
          <span><Person/> {option.firstName + ' ' + option.lastName}</span>
          {option.authUser.email}
        </React.Fragment>
      )}
      fullWidth
      onChange={(_, value) => { setValue(''); props.onUserSelected(value)}}
      onInputChange={(_, value) => setValue(value)}
      renderInput={(params : any) => (
        <TextField
          {...params}
          label="Choose User"
          variant="outlined"
          autoComplete=""
          autoCorrect=""
          inputProps={{
            ...params.inputProps,
            onKeyDown: handleEnterClicked,
            value: value,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default OutlinedAutoComplete;
