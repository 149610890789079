import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState, useCallback } from 'react';
import OutlinedDatePicker from '../../../basic-components/outlined-datepicker/outlined-datepicker';
import OutlinedSelect from '../../../basic-components/outlined-select/outlined-select';
import { StudentRecords, useArchive } from '../../../hooks/archive/archive';
import { useClasses } from '../../../hooks/classes/classes';
import { ArchiveModel, ArchiveRecordModel, EntityModel } from '../../../models/archive';
import { ClassModel } from '../../../models/classes';
import Person from '@mui/icons-material/Person';
import { AppBar, Tab, Tabs } from '@material-ui/core';


function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

type RenderStudentsProps<T extends ArchiveRecordModel> = {
    archiveEntities: EntityModel<T>[],
    allStudentsRecords: StudentRecords[],
    isRed: (_: string) => boolean
}



function RenderStudents<T extends ArchiveRecordModel>({archiveEntities, allStudentsRecords, isRed}: RenderStudentsProps<T>) {
    if(archiveEntities.length === 0) {
        return <div>No records for this class in the selected period!</div>
    }

    const sortedArchiveEntries = archiveEntities.sort((a, b) => a.date < b.date ? -1 : 1)

    return (
        <div style={{marginTop: '20px'}} className="classes-table-container">
                <table className="students-table">
                    <thead>
                        <tr>
                            <th key="sdgasdgfsdf23"><strong><Person/></strong></th>
                            <th key="sdgasdgfsdf24"><strong>Name</strong></th>
                            { sortedArchiveEntries.map(entity =><th key={entity.id}>{entity.name}</th>) }
                        </tr>
                    </thead>
                    <tbody>
                        {allStudentsRecords.map((student, idx) => (
                            <tr key={student.studentId}>
                                <td key="adgast436">{idx}</td>
                                <td key="adgast435">{student.studentName}</td>
                                { 
                                    sortedArchiveEntries.map(entity => 
                                        <td style={{color: isRed(student.allEntitiesIdsToVal.get(entity.id) || '') ? 'red' : 'black'}} key={student.studentId}><u>{student.allEntitiesIdsToVal.get(entity.id)}</u></td>
                                    )
                                }
                            </tr>
                        )) }
                    </tbody>
                </table>
        </div>
    ) 
}

type ArchiveEntityEnum = 'Attendance' | 'Homework' | 'Quizzes' | 'Exams'
const allEntities: ArchiveEntityEnum[] = ['Attendance', 'Homework', 'Quizzes', 'Exams']

type RenderEntityListProps = { 
    entity: ArchiveEntityEnum,
    archive: ArchiveModel,
    allStudentsRecords: StudentRecords[]
}

const RenderEntityList: React.VFC<RenderEntityListProps> = ({entity, archive, allStudentsRecords}) => {
    switch(entity) {
        case 'Attendance': return RenderStudents({ isRed: (str) => str === 'NO', archiveEntities: archive.attendances, allStudentsRecords })
        case 'Homework': return RenderStudents({ isRed: (str) => str === 'NO',  archiveEntities: archive.homeworks, allStudentsRecords })
        case 'Quizzes': return RenderStudents({ isRed: (str) => (parseFloat(str) || 16) < 9, archiveEntities: archive.quizzes, allStudentsRecords })
        case 'Exams': return RenderStudents({ isRed: (str) => (parseFloat(str) || 100) < 60, archiveEntities: archive.exams, allStudentsRecords })
    }
}

type RenderTabsProps = Omit<RenderEntityListProps, 'entity'>

const RenderTabs: React.VFC<RenderTabsProps> = ({archive, allStudentsRecords}) => {
    const [selectedEntity, setSelectedEntity] = useState<ArchiveEntityEnum>('Attendance')
    
    const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedEntity(allEntities[newValue]);
    };

    return (
            <AppBar position="static" color="transparent">
                <Tabs value={allEntities.indexOf(selectedEntity)} onChange={onChangeTab} aria-label="simple tabs example">
                {allEntities.map((val, index) => 
                    <Tab key={val +'tab' + index} label={val} {...a11yProps(index)} />
                )}
                </Tabs>
                <div className="archive-editor-container">
                    <RenderEntityList entity={selectedEntity} archive={archive} allStudentsRecords={allStudentsRecords} />
                </div>
            </AppBar>
    );
}

const StudentsPerformancePage: React.VFC = () => {
    const {classes, classesAreLoading } = useClasses();
    const [class_, setClass_] = useState<ClassModel | undefined>()
    const [startDate, setStartDate] = useState<Date>(new Date())
    const [endDate, setEndDate] = useState<Date>(new Date())

    

    const {isArchiveLoading, allStudentsRecords, archive} = useArchive({class_, startDate, endDate})

    const setDate = (oldDate: Date, setter: (_: Date) => void) => (date: MaterialUiPickersDate) => setter(date || oldDate)
    


    const onSelectedClassChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setClass_(classes.find(c => c.id === e.target.value));
    }, [classes, setClass_])
        
    if(classesAreLoading) {
        return null
    }


    return (
        <div className="list-container">
            <h2>Please Select the Filters</h2>
            <OutlinedSelect  label="Choose Class" items={classes.sort((a, b) => (a.classCategory.name + a.className) < (b.classCategory.name + b.className) ? -1 : 1).map(c => `${c.classCategory.name} --- ${c.className}`)} values={classes.map(c=> c.id)} optional={true} onChange={onSelectedClassChange} value={class_?.id || 0}/>
            <OutlinedDatePicker label={'From'} value={startDate} onChange={setDate(startDate, setStartDate)}/>
            <OutlinedDatePicker label={'To'} value={endDate} onChange={setDate(endDate, setEndDate)} />
            {!isArchiveLoading && archive && <RenderTabs archive={archive} allStudentsRecords={allStudentsRecords} />}
        </div>
    )
}

export default StudentsPerformancePage;