import TextField from '@material-ui/core/TextField';
import React from 'react';
import OutlinedDatePicker from '../../../../../basic-components/outlined-datepicker/outlined-datepicker';
import OutlinedSelect from '../../../../../basic-components/outlined-select/outlined-select';
import { ClassCategoryModel, ClassModel } from '../../../../../models/classes';




interface ClassInfoProps {
    class_ : ClassModel;
    setClass : (_: ClassModel) => void;
    classCategories : Array<ClassCategoryModel>;
}

function ClassInfoComponent(props : ClassInfoProps) {

    const setRequiredField = (key : keyof ClassModel, value : string | Date | ClassCategoryModel) => {
        console.log(value);
        props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, [key]: value});
    }    
    const setFees = (value : string | undefined | null) => {
        if(!value || value === '') return;
        var fees;
        try {
            fees = parseFloat(value);
        } catch(err) {
            console.log(err)
            return;
        }
        if(fees < 0) return;
        props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, fees: fees});
    }
    return (
        <div>
            <h3>Class Info</h3>
            <TextField error={props.class_.className === ''} id="outlined-basic" label="Class Name" variant="outlined" value={props.class_.className} onChange={(event) => { setRequiredField('className', event.target.value)}} fullWidth/>
            <OutlinedSelect optional={false} items={props.classCategories.map(cat => cat.name)} label='Class Category'  value={props.class_.classCategory.name} onChange={(event) => { setRequiredField('classCategory', new ClassCategoryModel(event.target?.value as string || props.class_.classCategory.name))}}/>
            <OutlinedDatePicker value={props.class_.startDate} label="Class Start Date" onChange={(date) => {setRequiredField('startDate', date || props.class_.startDate)}} />
            <OutlinedDatePicker value={props.class_.endDate} label="Class End Date" onChange={(date) => {setRequiredField('endDate', date || props.class_.endDate)}} />
            <TextField id="fees" label="Class Fees" type='number' InputLabelProps={{ shrink: true, }} fullWidth variant='outlined' value={props.class_.fees || 0} onChange={(e) => setFees(e.target.value)}/>
        </div>
    )
}

export default ClassInfoComponent;