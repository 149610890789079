import { Person } from '@mui/icons-material';
import React, {useContext, useState} from 'react';
import OutlinedSelect from '../../../basic-components/outlined-select/outlined-select';
import { ClassModel } from '../../../models/classes';
import { nilRankingModel, RankingModel, StudentRankModel } from '../../../models/ranking';
import ClassesService from '../../../services/classes.services';
import RankingService from '../../../services/ranking.service';
import CoreContext from '../../../state/contexts/core-context';
import { logAllErrors, reduce } from '../../../utils/utils';
import './ranking.css';


export default function StudentsRankingPage() {
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const [ranking, setRanking] = useState<RankingModel>(nilRankingModel);
    const coreContext = useContext(CoreContext);
    
    React.useEffect(() => {
        interface LoadingProgress {
            classes : boolean;
        }
        const loadingProgress : LoadingProgress = {
            'classes': false,
        };
        const serverDataSettersCollection = {
            'classes': setClasses,
        }

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        });
        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 
        coreContext.setLoading(true);
        ClassesService.fetchAllClasses().then((classes) => {
            handleFetchedData('classes', classes);
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
        }).finally(stopLoadingIfAllDone);
         // eslint-disable-next-line
    }, []);
    /***Selected Class***/
    const [selectedClass, setSelectedClass] = useState<ClassModel>();
    const onSelectedClassChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if(!e.target.value || e.target.value === '') return;
        
        const selectedClass = classes.find(c => c.id === e.target.value);
        
        if(!selectedClass) return;

        setSelectedClass(selectedClass);
        coreContext.setLoading(true)
        RankingService.fetchRankings(selectedClass).then(ranking => {
            setRanking(ranking);
        }).finally(() => coreContext.setLoading(false));
    }

    const renderStudents = () => {
        return ranking.rankings.map((rank : StudentRankModel, index : number) => (
            <tr  key={"tr"+index}>
                <td>{ rank.rank }</td>
                <td>{ rank.student.firstName + ' ' + rank.student.lastName }</td>
                <td>{ rank.student.nickName || '___' }</td>
                <td>{ rank.student.school || '___'}</td>
                <td>{ (rank.score * 100).toFixed(2) + '%' }</td>
            </tr>
        )); 
    }
    const renderRankingList = () => {
        if(!selectedClass) return (null);
        return (
            <div className="students-table-container">
                <table className="students-table">
                    <thead>
                        <tr>
                            <th><Person/></th>
                            <th>Name</th>
                            <th>Nickname</th>
                            <th>School</th>
                            <th>Score %</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderStudents()}
                    </tbody>
                </table>
            </div>
        )
        
    }
    
    return (
        <div className="list-container">
            <h2>Please Select a Class</h2>
            <OutlinedSelect  label="Choose Class" items={classes.map(c => c.className)} values={classes.map(c=> c.id)} optional={true} onChange={onSelectedClassChange} value={selectedClass?.id || 0}/>
            <div className="ranking-list-container">
                { renderRankingList() }
            </div>
        </div>
    )
}