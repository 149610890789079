import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';

setAuthOnAxios(Axios);

const LayoutService = {
    async fetchAllClassCategory() : Promise<Array<string>>{
        const url = settings.baseUrl + 'api/class/class-category/all'
        var res = null;
        try {
            res = await Axios.get(url);
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            window.location.reload();
            console.log(err.response);
            return [];
        }
        try {
            return res?.data.map((value : any, index : number) => value.name);
        } catch(err) {
            throw new Error('Unexpected response format');
        }
    }
}

export default LayoutService;