import { checkAllRequiredFields } from "../utils/utils";
import { ClassModel } from "./classes";
import { DateModel, makeDateStr } from "./Date";


export abstract class ArchiveRecordModel {
    readonly student : number;
    strBuffer : string = '';
    
    setRecordValue(_? : boolean, grade? : number) {

    }
    protected serializeHelper() {
        return {
            student: this.student
        };
    }

    abstract maxVal() : number;
    abstract serialize() : any;
    abstract create_same_type_obj(obj : any) : ArchiveRecordModel;
    abstract numVal() : number | undefined; 
    abstract boolVal() : boolean | undefined;
    abstract isDefinedVal() : boolean;

    abstract isRed(): boolean;

    constructor(obj : any) {
        this.student = obj['student'];
    }
}
export class QuizRecordModel extends ArchiveRecordModel {
    grade? : number;
    setRecordValue(_? : boolean, grade? : number) {
        this.grade = grade;
    }
    create_same_type_obj(obj : any) {
        return new QuizRecordModel(obj);
    }
    numVal() { return this.grade; }
    boolVal() { return false; }
    isDefinedVal() { return this.grade !== undefined;}
    maxVal() { return 15; }
    serialize() {
        return {...this.serializeHelper(), grade: this.grade}
    }

    isRed(): boolean {
        return this.grade !== undefined && this.grade !== null && this.grade < 9    
    }

    constructor(obj : any) {
        super(obj);
        this.grade  = obj['grade'];
        this.strBuffer = this.grade?.toString() || '';
    }
}

export class ExamRecordModel extends ArchiveRecordModel {
    grade? : number;
    setRecordValue(_? : boolean, grade? : number) {
        this.grade = grade;
    }

    maxVal() { return 100; }
    numVal() { return this.grade; }
    boolVal() { return false; }
    isDefinedVal() { return this.grade !== undefined;}

    isRed() { return this.grade !== null && this.grade !== undefined && this.grade < 60 }

    create_same_type_obj(obj : any) {
        return new ExamRecordModel(obj);
    }
    serialize() {
        return {...this.serializeHelper(), grade: this.grade}
    }
    constructor(obj : any) {
        super(obj);
        this.grade  = obj['grade'];
        this.strBuffer = this.grade?.toString() || '';
    }
}

export class AttendanceRecordModel extends ArchiveRecordModel {
    ok? : boolean;
    setRecordValue(ok? : boolean, _? : number) {
        this.ok = ok;
    }
    create_same_type_obj(obj : any) {
        return new AttendanceRecordModel(obj);
    }
    serialize() {
        return {...this.serializeHelper(), ok: this.ok}
    }

    isRed() { return this.ok === false }

    boolVal() { return this.ok }
    numVal() {  return 0; }
    isDefinedVal() { return this.ok !== undefined;}
    maxVal() { return 1; }
    constructor(obj : any) {
        super(obj);
        this.ok  = obj['ok'];
        this.strBuffer = this.ok !== undefined ? this.ok + '' : '';
    }
}
export class HomeworkRecordModel extends ArchiveRecordModel {
    ok? : boolean;
    setRecordValue(ok? : boolean, _? : number) {
        this.ok = ok;
    }
    create_same_type_obj(obj : any) {
        return new HomeworkRecordModel(obj);
    }
    serialize() {
        return {...this.serializeHelper(), ok: this.ok}
    }

    isRed() { return this.ok === false }

    maxVal() { return 1; }
    boolVal() { return this.ok; }
    numVal() { return 0; }
    isDefinedVal() { return this.ok !== undefined;}

    constructor(obj : any) {
        super(obj);
        this.ok  = obj['ok'];
        this.strBuffer = this.ok !== undefined ? this.ok + '' : '';
    }
}

export abstract class EntityModel<T extends ArchiveRecordModel> {
    readonly id : number;
    readonly name : string;
    readonly classId: number;
    readonly date : Date;

    viewMyRecords : boolean = false;

    dirty : boolean = false;
    toggleViewMyRecords(){
        this.viewMyRecords = !this.viewMyRecords;
        return this;
    }

    toString() : string {
        return this.name + ' (' + this.date.toLocaleDateString('en-UK') + ')'
    } 


    records : Array<T> = [];

    abstract insertIntoArchive(archive : ArchiveModel) : void;
    abstract updateEntityInArchive(archive : ArchiveModel) : void;
    abstract removeFromArchive(archive : ArchiveModel) : void;

    constructor(obj : any) {
        checkAllRequiredFields(obj, ['id', 'name', 'myclass', 'date']);
        this.id = obj['id'];
        this.name = obj['name'];
        this.classId = obj['myclass'];
        this.date = new DateModel(obj['date']);
    }
}


export function serializeArchiveEntityModel(entity : EntityModel<ArchiveRecordModel>) : any {
    return {
        id: entity.id,
        name: entity.name,
        myclass:  entity.classId, 
        date: makeDateStr(entity.date),
        records: entity.records.map(record => record.serialize())
    };
}

export class QuizModel extends EntityModel<QuizRecordModel> {

    serialize() {
        return {...serializeArchiveEntityModel(this), quiz: this.id};
    }
    removeFromArchive(archive : ArchiveModel){
        const index = archive.quizzes.indexOf(this);
        if(index !== -1) {
            archive.quizzes.splice(index, 1);
        }
    }

    updateEntityInArchive(archive : ArchiveModel) {
        const index = archive.quizzes.findIndex((val) => val.id === this.id);
        if(index === -1) return;
        archive.quizzes.splice(index, 1);

        this.insertIntoArchive(archive);
    }
    insertIntoArchive(archive : ArchiveModel) {
        const index = archive.quizzes.findIndex((val) => val.date < this.date);
        if(index === -1)
            archive.quizzes.push(this);
        else
            archive.quizzes.splice(index, 0, this);
    }

    constructor(obj : any) {
        super(obj);
        this.records = obj['records'].map((record : any) => new QuizRecordModel(record))
    }
}
export class HomeworkModel extends EntityModel<HomeworkRecordModel> {
    serialize() {
        return {...serializeArchiveEntityModel(this), homework: this.id};
    }
    removeFromArchive(archive : ArchiveModel) {
        const index = archive.homeworks.indexOf(this);
        if(index !== -1) {
            archive.homeworks.splice(index, 1);
        }
    }

    updateEntityInArchive(archive : ArchiveModel) {
        const index = archive.homeworks.findIndex((val) => val.id === this.id);
        if(index === -1) return;
        archive.homeworks.splice(index, 1);

        this.insertIntoArchive(archive);
    }

    insertIntoArchive(archive : ArchiveModel) {
        const index = archive.homeworks.findIndex((val) => val.date < this.date);
        if(index === -1)
            archive.homeworks.push(this);
        else
            archive.homeworks.splice(index, 0, this);
    }
    constructor(obj : any) {
        super(obj);
        this.records = obj['records'].map((record : any) => new HomeworkRecordModel(record))
    }
}
export class AttendanceModel extends EntityModel<AttendanceRecordModel> {
    serialize() {
        return {...serializeArchiveEntityModel(this), attendance: this.id};
    }
    removeFromArchive(archive : ArchiveModel) {
        const index = archive.attendances.indexOf(this);
        if(index !== -1) {
            archive.attendances.splice(index, 1);
        }
    }

    updateEntityInArchive(archive : ArchiveModel) {
        const index = archive.attendances.findIndex((val) => val.id === this.id);
        if(index === -1) return;
        archive.attendances.splice(index, 1);

        this.insertIntoArchive(archive);
    }

    insertIntoArchive(archive : ArchiveModel) {
        const index = archive.attendances.findIndex((val) => val.date < this.date);
        if(index === -1)
            archive.attendances.push(this);
        else
            archive.attendances.splice(index, 0, this);
    }

    constructor(obj : any) {
        super(obj);
        this.records = obj['records'].map((record : any) => new AttendanceRecordModel(record))
    }
}
export class ExamModel extends EntityModel<ExamRecordModel> {
    serialize() {
        return {...serializeArchiveEntityModel(this), exam: this.id};
    }
    removeFromArchive(archive : ArchiveModel) {
        const index = archive.exams.indexOf(this);
        if(index !== -1) {
            archive.exams.splice(index, 1);
        }
    }

    updateEntityInArchive(archive : ArchiveModel) {
        const index = archive.exams.findIndex((val) => val.id === this.id);
        if(index === -1) return;
        archive.exams.splice(index, 1);

        this.insertIntoArchive(archive);
    }

    insertIntoArchive(archive : ArchiveModel) {
        const index = archive.exams.findIndex((val) => val.date < this.date);
        if(index === -1)
            archive.exams.push(this);
        else
            archive.exams.splice(index, 0, this);
    }
    constructor(obj : any) {
        super(obj);
        this.records = obj['records'].map((record : any) => new ExamRecordModel(record))
    }
}

export class ArchiveModel {
    readonly quizzes : Array<QuizModel> = [];
    readonly exams : Array<ExamModel> = [];
    readonly attendances : Array<AttendanceModel> = [];
    readonly homeworks :  Array<HomeworkModel> = [];
    constructor(obj : any) {
        checkAllRequiredFields(obj, ['quiz', 'exam', 'attendance', 'homework']);
        this.quizzes = obj['quiz'].map((q : any) => new QuizModel(q));
        this.exams = obj['exam'].map((e : any) => new ExamModel(e));
        this.homeworks = obj['homework'].map((h : any) => new HomeworkModel(h));
        this.attendances = obj['attendance'].map((a : any) => new AttendanceModel(a));

        this.quizzes.sort((a, b) => a.date > b.date ? -1 : 1);
        this.exams.sort((a, b) => a.date > b.date ? -1 : 1);
        this.homeworks.sort((a, b) => a.date > b.date ? -1 : 1);
        this.attendances.sort((a, b) => a.date > b.date ? -1 : 1);

    }

}




/********************************
 * 
 * Nils
 * Empty Model objects createors
 * 
 *********************************/
export const nilArchive = new ArchiveModel({quiz: [], exam: [], homework: [], attendance: []});
export const createNilQuiz = (class_ : ClassModel, name : string) : QuizModel => new QuizModel({
    id: 0,
    name: name,
    myclass: class_.id,
    date: makeDateStr(class_.lastSessionDate()),
    records: []
});

export const createNilExam = (class_ : ClassModel, name : string) : QuizModel => new ExamModel({
    id: 0,
    name: name,
    myclass: class_.id,
    date: makeDateStr(class_.lastSessionDate()),
    records: []
});

export const createNilAttendace = (class_ : ClassModel, name : string) : AttendanceModel => new AttendanceModel({
    id: 0,
    name: name,
    myclass: class_.id,
    date: makeDateStr(class_.lastSessionDate()),
    records: []
});

export const createNilHomework = (class_ : ClassModel, name : string) : HomeworkModel => new HomeworkModel({
    id: 0,
    name: name,
    myclass: class_.id,
    date: makeDateStr(class_.lastSessionDate()),
    records: []
});

export const nilQuizRecord = new QuizRecordModel({ student: 0 });
export const nilExamRecord = new ExamRecordModel({ student: 0 });
export const nilHomeworkRecord = new HomeworkRecordModel({ student: 0 });
export const nilAttendanceRecord = new AttendanceRecordModel({ student: 0 });