import React, {useState} from 'react';
import { ArchiveRecordModel, createNilAttendace, EntityModel } from '../../../../models/archive';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Assessment from '@mui/icons-material/Assessment';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Button, Collapse, makeStyles } from '@material-ui/core';
import { Delete, Edit } from '@mui/icons-material';
import ArchiveService from '../../../../services/archive.service';
import { logAllErrors, logSuccess } from '../../../../utils/utils';
import ModalComponent from '../../../../basic-components/modal/modal.component';
import ManageEntityPage from '../manage-entity/manage-entity';
import { ClassModel, nilClass } from '../../../../models/classes';
import RecordsListComponent from './records-list/records-list';
import { StudentModel } from '../../../../models/users';
import ArchiveRecordInputProps from '../../../../basic-components/archive-record-input/props-interface';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

interface EntityListProps {
    entityObjects : Array<EntityModel<ArchiveRecordModel>>;
    onEntityDelete : (_ : EntityModel<ArchiveRecordModel>) => void;
    updateEntityInArray : (_ : EntityModel<ArchiveRecordModel>) => void;
    entityType : 'Attendance'|'Homework'|'Quizzes'|'Exams';
    selectedClass : ClassModel;
    updateServiceFunc : (_ : EntityModel<ArchiveRecordModel>) => Promise<EntityModel<ArchiveRecordModel>>;
    allEnrolledStudents : Array<StudentModel>;
    recordFactory : ArchiveRecordModel;
    recordInputComponent : (_ : ArchiveRecordInputProps) => React.ReactElement;

}
export default function EntityList(props : EntityListProps) {
    
    const classes = useStyles();


    /*********Toggle**********/
    /**************Delete Entity******************/
    const onDelete = (value : EntityModel<ArchiveRecordModel>) => {
        if(!window.confirm('Are you sure you want to delete ' + value.name)) return;

        ArchiveService.deleteArchiveEntity(value).then(() => {
            props.onEntityDelete(value);
            logSuccess(value.name + ' was deleted successfully!')
        }).catch(err => logAllErrors(err));
    }
    
    /****************Edit Entity*******************/
    const [selectedEntity, setSelectedEntity] = useState<EntityModel<ArchiveRecordModel>>(createNilAttendace(nilClass, ''));
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

    const onEditOpen = (entity : EntityModel<ArchiveRecordModel>) => {
       setSelectedEntity(entity);
       setIsEditOpen(true);
    }

    const onCloseEdit = () => setIsEditOpen(false);

  
    const onEditSaved =  (entity : EntityModel<ArchiveRecordModel>) => {
        onCloseEdit();
        props.updateEntityInArray(entity);
    }

    /********Update Entities Record**********/
    const updateEntity = (entity : EntityModel<ArchiveRecordModel>) => {
        props.updateEntityInArray(entity);
    }

    /*********Rendering Helpers************/
    const renderEntityList  = () => {

        return props.entityObjects.map((val, index) =>
            (
                <div key={'div' + props.entityType + index}>
                    <ListItem button key={props.entityType + index} onClick={(e) => props.updateEntityInArray(val.toggleViewMyRecords())}>
                        <ListItemIcon>
                            <Assessment />
                        </ListItemIcon>
                        <ListItemText primary={val.toString()}/>
                        {val.viewMyRecords ? <ExpandLess/> : <ExpandMore/>}
                        <Button onClick={(e) => { e.stopPropagation(); onEditOpen(val); }}>
                            <Edit color="primary"/>
                        </Button>
                        <Button onClick={(e) => { e.stopPropagation(); onDelete(val); }}>
                            <Delete color="secondary"/>
                        </Button>
                    </ListItem>
                    <Collapse key={'collapse' + props.entityType + index} in={val.viewMyRecords} timeout="auto" unmountOnExit>
                        <RecordsListComponent onRecordUpdate={updateEntity} recordInputComponent={props.recordInputComponent} recordFactory={props.recordFactory} students={props.allEnrolledStudents}  entity={val} />
                    </Collapse>
                </div>
            )
        );
            
    }

    /**************Render******************/
    return (
        <div>
            <ModalComponent closeModal={onCloseEdit} isOpen={isEditOpen}><ManageEntityPage onClose={onCloseEdit} class_={props.selectedClass || nilClass} onSaveSuccess={onEditSaved} operationType={'Edit'} entityType={props.entityType} entityObject={selectedEntity} serviceFunc={props.updateServiceFunc}/></ModalComponent>
            <List
            className={classes.root}
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        { props.entityType }
                    </ListSubheader>
                }
            >
                { renderEntityList() }   
            </List>
        </div>
    )
}