import React, {useState} from 'react';
import { AuthUserModel, StudentModel, StudentParentModel } from '../../../../models/users';
import './edit-student.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GenderModel } from '../../../../models/gender';
import OutlinedSelect from '../../../../basic-components/outlined-select/outlined-select';
import OutlinedDatePicker from '../../../../basic-components/outlined-datepicker/outlined-datepicker';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SaveIcon from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import { logAllErrors, logSuccess } from '../../../../utils/utils';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {purpleColor} from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);

interface EditPageProps {
    title: string,
    student : StudentModel;
    onSave : (_ : StudentModel) => void;
    genders : Array<GenderModel>;
    onClose : () => void;
    serviceFunc : (_ : StudentModel) => Promise<StudentModel>;
}; 
export default function EditStudentPage(props : EditPageProps) {
    /******Loading Animation********/
    const [loading, setLoading] = useState<boolean>(false);
    const renderLoader = () => {
        if(loading)
            return (
                <div className="spinner-container"> 
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        return (null);
    }



    /************User Management***************/
    const classes = useStyles();
    const [student, setStudent] = useState<StudentModel>(props.student);
    const setRequiredField = (key : keyof StudentModel, value : string | Date | AuthUserModel) => {
        setStudent({...student, [key]: value});
    }
    const setOptionalField = (key : keyof StudentModel, value? : string | StudentParentModel) => {
        if(value === undefined || value === '') 
            setStudent({...student, [key]: undefined});
        else
            setStudent({...student, [key]: value});
    }

    const isNull = (key : keyof StudentParentModel, value? : StudentParentModel) => {
        return (!value || !value[key] || value[key] === '');
    }
    const validate = () => {
        var count = 0;
        const keys : Array<keyof StudentParentModel> = ['name', 'email'];
        var motherCount = keys.filter(key => !isNull(key, student.mother)).length;
        var fatherCount = keys.filter(key => !isNull(key, student.father)).length;
        student.father = fatherCount > 0 ? student.father : undefined;
        student.mother = motherCount > 0 ? student.mother : undefined;
        count = motherCount + fatherCount;

        if(count === 0){
            logAllErrors('You need to supply at least one of the parents');
            return false;
        }
        if(count % 2 === 1) {
            logAllErrors('You need to supply both the email and the name for the parent(s) you supplied');
            return false;
        }
        return true;
    }
    const onSave = async () => {
        if(!validate()) return;
        setLoading(true);
        props.serviceFunc(student).then(_ => {
            logSuccess('Student Update Sucessfully');
            props.onSave(student);
        }).catch(err => {
            logAllErrors(err);
            console.error(err);
        }).finally(() => setLoading(false));
    }

    return (
        <div className="student-page-container">
            <div className="student-edit-page-title">
                <h2>{props.title}</h2>
            </div>
            <div className="form-container">
                <h3>Personal Info</h3>
                <TextField error={student.firstName === ''} id="outlined-basic" label="First Name" variant="outlined" value={student.firstName} onChange={(event) => { setRequiredField('firstName', event.target.value || '')}} fullWidth/>
                <TextField error={student.lastName === ''} id="outlined-basic" label="Last Name" variant="outlined"  value={student.lastName} onChange={(event) => { setRequiredField('lastName', event.target.value || '')}} fullWidth/>
                <TextField id="outlined-basic" label="Nickname" variant="outlined"  value={student.nickName || ''} onChange={(event) => { setOptionalField('nickName', event.target.value || '')}} fullWidth/>
                <OutlinedSelect value={student.gender} optional={false} label="Gender" items={props.genders.map(gender => gender.gender)} onChange={(event) => { setRequiredField('gender', event.target.value as string || '')} }/>
                <OutlinedDatePicker value={student.birthdate} label="Birthdate" onChange={(event) => { setRequiredField('birthdate', event || student.birthdate)}}/>
            </div>
            <div className="form-container">
                <h3>Contacts Info</h3>
                <TextField  error={student.authUser.email === ''} id="outlined-basic" label="Email" variant="outlined" value={student.authUser.email}  onChange={(event) => { setRequiredField('authUser', {email: event.target.value || '', id: student.authUser.id})} } fullWidth/>
                <TextField id="outlined-basic" label="Phone Number" variant="outlined"  value={student.phoneNumber || ''} onChange={(event) => { setOptionalField('phoneNumber', event.target.value || '')} } fullWidth/>
                <TextField id="outlined-basic" label="Facebook Account Name" variant="outlined" value={student.facebookUrl}  onChange={(event) => { setRequiredField('facebookUrl', event.target.value || '') }} fullWidth/>
            </div>
            <div className="form-container">
                <h3>Parents Info</h3>
                <TextField id="outlined-basic" label="Mother's Name" variant="outlined" value={student.mother?.name || ''} onChange={(event) => { setOptionalField('mother', {name: event.target.value || '',  email: student.mother?.email || ''})} } fullWidth/>
                <TextField id="outlined-basic" label="Mother's Email" variant="outlined"  value={student.mother?.email || ''}  onChange={(event) => { setOptionalField('mother', {name: student.mother?.name || '',  email: event.target.value})} } fullWidth/>
                <TextField id="outlined-basic" label="Mother's Mobile No." variant="outlined"  value={student.mother?.phoneNumber || ''}  onChange={(event) => { setOptionalField('mother', {name: student.mother?.name || '', email: student.mother?.email || '', phoneNumber: event.target.value})}} fullWidth/>
                <TextField id="outlined-basic" label="Father's Name" variant="outlined"  value={student.father?.name || ''} onChange={(event) => { setOptionalField('father', {name: event.target.value|| '',  email: student.father?.email || ''})} } fullWidth/>
                <TextField id="outlined-basic" label="Father's Email" variant="outlined"  value={student.father?.email || ''}  onChange={(event) => { setOptionalField('father', {name: student.father?.name || '',  email: event.target.value})} } fullWidth/>
                <TextField id="outlined-basic" label="Father's Mobile No." variant="outlined"  value={student.father?.phoneNumber || ''}  onChange={(event) => { setOptionalField('father', {name: student.father?.name || '', email: student.father?.email || '', phoneNumber: event.target.value})}} fullWidth/>

            </div>
            <div className="form-container">
                <h3>School Info</h3>
                <TextField id="outlined-basic" label="School Name" variant="outlined" value={student.school} onChange={(event) => { setRequiredField('school', event.target.value || '')}} fullWidth/>
            </div>

            <div className="edit-student-submit">
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={onSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    startIcon={<Close />}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </div>
            {renderLoader()}
        </div>
    
    )
}