import { store } from 'react-notifications-component';
import { ArchiveRecordModel, EntityModel } from '../models/archive';


const gatherStringsFromObj = (obj : any) : Array<string> => {
    if(typeof(obj) !== 'object')
        return [obj + ""];
    
    var ret : Array<string> = []

    for(const key in obj)
        ret = ret.concat(gatherStringsFromObj(obj[key]).map(str => key + ': ' + str));
    
    return ret;
}
export const logSuccess = (str : string) : void => {
    store.addNotification({
        title: "Successful operation!",
        message: str,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration: 1000000,
        }
    });
}

export const logAllErrors = (obj : any) : void => {
    const errorsStr = gatherStringsFromObj(obj);
    for(const err of errorsStr)
        store.addNotification({
            title: "Failure",
            message: err || '',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
            duration: 1000000,
            }
        });  
}

/**
 * @brief custom reduce function for a generic Array
 * @param arr Array
 * @param acc The accumelator value
 * @param reducer the function to be called on each element. It should accept an acc of the same type of acc
 */
export function reduce<T, X>(arr : Array<T>, acc : X, reducer : (acc : X, curVal : T) => X) : X {
    if(arr.length === 0) return acc;
    else return reduce(arr.slice(1, arr.length), reducer(acc, arr[0]), reducer);
}


/**
 * @brief Makes sure that all the fields passed into the array fields is present
 *        in the object obj.
 * @param obj the objects whose fields are to be checked
 * @param fields array of string contains the name of the fields to be checked on obj
 * @throws an Error object if one or more of the fields are not present
 */
export const checkAllRequiredFields = (obj : any, fields : Array<string>) => {
    const missingFields = fields.filter(field => !(field in obj));
    if(missingFields.length > 0)
        throw new Error('Those required fields are not present in the received object: ' + missingFields);
}

/**
 * @brief Auto generates a name for a new Archive Entity
 * @param entityType : string => a string that will be prepended to a number (e.g "Homework")
 * @param entites : EntityModel<ArchiveRecordModel> => array of all the existing entities. 
 * @returns string an auto generated name
 */
export const autoGenerateNewEntityName = (entityType : string, entities : Array<EntityModel<ArchiveRecordModel>>) => {
    var biggestNumber : number = 0;
    for(const entity of entities) {
        const match = entity.name.match(/\d+/g);
        if(match && (match.length || 0) > 0){
            var num = parseInt(match[match.length - 1]);
            biggestNumber = Math.max(biggestNumber, num);
        }
    }
    return entityType + ' ' + (biggestNumber + 1);
}

/***
 * Modulo operator because Javascript's "%" operator sucks big time
 * e.g. -1 % 7 = -1 and not 6
 * 
 * using this function mod(-1, 7) = 6
 */
export const mod = (x : number, mod : number) => {
    return ((x % mod) + mod) % mod;
}


export const concatenateAllMaps = <R, T>(maps: Map<R, T>[]): Map<R, T> => {
    const concatenation = new Map<R, T>()
    
    maps
    .flatMap((it) => [...it.entries()])
    .forEach((it) => concatenation.set(it[0], it[1]))

    return concatenation
}