import settings from '../settings.json';
import Axios from 'axios';

export function setAuthOnAxios(a: typeof Axios) {
    const token = localStorage.getItem('token');
    if(!token) {
        console.error('Token is not set');
    }
    else{
        a.defaults.headers.common['Authorization'] = 'Token ' + token;
    }
}

const AuthService = {

    resetPassword : async function(token : string, userId : number, password : string) {
        const data = {
            user_id: userId,
            token: token,
            password: password
        }
        const url = settings.baseUrl + 'api/user/reset-password';
        try {
            await Axios.put(url, data);
        } catch(err) {
            throw err.response?.data;
        }
    },
    verifyAuthorization: async function(){
        const url =  settings.baseUrl + 'api/user/verify-portal-rights';
        try{
            await Axios.get(url)
            return true;
        }
        catch(err) {
            return false
        }
    },
    login : function (email : string, password : string) : Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const formData = new FormData()
            formData.append('username', email);
            formData.append('password', password);
            const url = settings.baseUrl + 'api/user/auth';

            try {
                const res = await Axios.post(url, formData);
                Axios.defaults.headers.common['Authorization'] = 'Token ' + res.data.token;
                if(! await this.verifyAuthorization())
                    return reject({'err': 'you do not have sufficient authorization to login!'});
                localStorage.setItem('token', res.data.token);
                return resolve('Login Successful. You will be redirected shortly');
            }
            catch(err) {
                return reject(err?.response?.data || 'Could not connect! Please check you internet connection');
            }
        })

    }
}

export default AuthService;
