import React, { useContext, useState } from 'react';
import { ClassModel, nilClass } from '../../../models/classes';
import ClassesService from '../../../services/classes.services';
import CoreContext from '../../../state/contexts/core-context';
import { ClassNotification } from '../../../models/notification';
import SendNotificationService from '../../../services/send-notification.service';
import { logAllErrors, logSuccess, reduce } from '../../../utils/utils';
import OutlinedSelect from '../../../basic-components/outlined-select/outlined-select';
import { Button, TextField } from '@material-ui/core';
import { PacmanLoader } from 'react-spinners';
import { purpleColor } from '../../../constants';
import { Send } from '@mui/icons-material';


function SendNotificationPage() {
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const coreContext = useContext(CoreContext);

    const [notification, setNotification] = useState<ClassNotification>(new ClassNotification());

    const onNotificationAttrChange = (key : keyof ClassNotification, val : string | ClassModel) => {
        setNotification({...notification, serialize: notification.serialize, [key]: val});
    }

    const sortByCategory = (classes : Array<ClassModel>) : Array<ClassModel> => {
        return classes.sort((c1, c2) => 
            (c1.classCategory.name + c1.className) < (c2.classCategory.name + c2.className) ? -1 : 1
        )
    }
    const sendNotification = () => {
        setLoading(true);
        SendNotificationService.sendNotification(notification)
        .then(() => logSuccess('Notification sent to all students in this class'))
        .catch((err) => logAllErrors(err))
        .finally(() => setLoading(false));
    }

    /*********** Initialization **************/
    React.useEffect(() => {
        interface LoadingProgress {
            classes : boolean;
        }
        const loadingProgress : LoadingProgress = {
            'classes': false,
        };
        const serverDataSettersCollection = {
            'classes': setClasses,
        }

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        });
        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 
        coreContext.setLoading(true);
        ClassesService.fetchAllClasses().then((classes) => {
            handleFetchedData('classes', sortByCategory(classes));
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
        }).finally(stopLoadingIfAllDone);
         // eslint-disable-next-line
    }, []);
    
    return (
        <div className="list-container">
        <h2>Please Select a Class</h2>
        
        <div className="compose-email-container">
            <div className="select-class-email-container">
                <OutlinedSelect  label="Choose Class"
                    items={classes.map(c => c.classCategory.name + '---' + c.className)}
                    values={classes.map(c=> c.id)}
                    optional={true}
                    onChange={(e) =>
                        onNotificationAttrChange('class_', classes.find((c) => c.id === e.target.value) || nilClass)
                    } 
                    value={notification.class_.id || 0}
                 />
            </div>
            <div className="email-subject-container">
                <TextField
                    error={notification.title === ''}
                    fullWidth
                    label="Title"
                    value={notification.title}
                    variant='outlined'
                    onChange={(e) => onNotificationAttrChange('title', e.target.value)}
                    size="small"
                />
            </div>
            <div className="email-body-container">
                <TextField
                        error={notification.body === ''}
                        fullWidth
                        label="Body"
                        value={notification.body}
                        variant='outlined'
                        onChange={(e) => onNotificationAttrChange('body', e.target.value)}
                        size="small"
                    />
            </div>
            <div className="ecr-send-reports-button">
                    {loading ? (<div className="ecr-loader"><PacmanLoader size={15} color={purpleColor}/></div>) : (null)}
                    <div className="ecr-button">
                        <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<Send />}
                        onClick={sendNotification}
                        >
                            Send
                        </Button>
                    </div>
                </div>
        </div>
    </div>
    )
}

export default SendNotificationPage;