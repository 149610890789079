import { FormControl, FormHelperText, Input, InputAdornment } from '@material-ui/core';
import React from 'react';
import ArchiveRecordInputProps from '../props-interface';
import './grade-input.css'

export default function GradesInputComponent(props : ArchiveRecordInputProps) {
    const maxGrade = props.maxGradeVal || 100;

    /**
     * Validates the number string before parsing it, then parses it as a floating
     * number.
     *
     * @param val The number string to be parsed
     * @returns
     * 1. Undefined if the val is the empty string or undefined
     * 2. -1 if the string is not a valid floating point format, 
     *      or the number is not in the interval [0, MaxGrade]
     * 3. The parsed number if 1 and 2 does not hold
     */
    const parseInputGrade = (val : string) : number | undefined => {
        if(!val) return undefined;
        const grade = parseFloat(val);
        if(grade === undefined || isNaN(grade)) return -1;
        else if(grade < 0 || grade > maxGrade) return -1;
        else return grade
    }
    const onInputChange = (val : string) => {
        /**parse and make sure the input is a float and nothing else**/
        var inputGrade = parseInputGrade(val)
        const newRecord = props.record.create_same_type_obj({student: props.record.student});
        newRecord.setRecordValue(undefined, inputGrade);
        newRecord.strBuffer = val;
        props.setRecordValue(newRecord);
    }
    return (
        <div className="grade-input-container">
            <FormControl>
                <Input
                    error={props.record.numVal() === -1}
                    id="standard-adornment-grade"
                    value={props.record.strBuffer}
                    style={{
                        color: props.record.isRed() ? 'red' : 'black'
                    }}
                    onChange={(e) => onInputChange(e.target.value)}
                    endAdornment={<InputAdornment position="end">/{maxGrade}</InputAdornment>}
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                    'aria-label': 'grade',
                    }}
                    fullWidth
                />
                <FormHelperText id="standard-weight-helper-text">Grade</FormHelperText>
            </FormControl>
        </div>
    )
}

export function QuizGradesInputComponent(props : ArchiveRecordInputProps) {
    return GradesInputComponent({...props, maxGradeVal: 15});
}
export function ExamGradesInputComponent(props : ArchiveRecordInputProps) {
    return GradesInputComponent({...props, maxGradeVal: 100})
}