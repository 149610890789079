import React, {useContext, useState} from 'react';
import CoreContext from '../../../state/contexts/core-context';
import './students.css';
import UsersService from '../../../services/users.service';
import OptionsService from '../../../services/options.service';

import { logAllErrors, logSuccess } from '../../../utils/utils';
import { StudentModel, nilStudent } from '../../../models/users';
import Person from '@mui/icons-material/Person';
import SearchBarComponent from '../../../basic-components/searchbar/searchbar.component';
import { ChangeEvent } from 'react';
import ModalComponent from '../../../basic-components/modal/modal.component';
import EditStudentPage from './edit-student/edit-student';
import { GenderModel } from '../../../models/gender';
import UserService from '../../../services/users.service';
import  Button  from '@material-ui/core/Button';
import  Add  from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Loop from '@mui/icons-material/Loop';

interface LoadingProgress {
    students : boolean;
    genders : boolean;
}; 
function StudentsPage()
{

    /*****Page Initialization******/
    const coreContext = useContext(CoreContext); 
    const [loaded, setLoaded] = useState<boolean>(false);
    const [students, setStudents] = useState<Array<StudentModel>>([]);
    const [allStudents, setAllStudents] = useState<Array<StudentModel>>([]);
    const [allGenders, setAllGenders] = useState<Array<GenderModel>>([]);

    const serverDataSettersCollection = {
        'students': setAllStudents,
        'genders': setAllGenders,
    }

    React.useEffect(() => {
        // this prevents the component of being rendered recursively 
        // every time coreContext.setLoading is called
        if(loaded) return;
        setLoaded(true);
        const loadingProgress : LoadingProgress = {
            'students': false,
            'genders': false,
        };
        const loadingStatus = () => { return !loadingProgress.genders || !loadingProgress.students }
        coreContext.setLoading(true);

        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
            coreContext.setLoading(loadingStatus());
        }


        /**Load All Students**/
        UsersService.fetchAllStudents().then(students => {
            setStudents(students);
            students.sort((s1, s2) => (s1.firstName + s1.lastName) < (s2.firstName + s2.lastName) ? -1 : 1);
            handleFetchedData('students', students);

        }).catch(err => {
            console.error(err);
            logAllErrors(err);
        });

        /**Load All Genders**/
        OptionsService.fetchAllGenders().then((genders) => {
           handleFetchedData('genders', genders);
        }).catch(err => {
            console.error(err);
            logAllErrors(err);
        });
        

        // eslint-disable-next-line
    }, [])



    /******** Modal Events ***********/
    // Edit Student Modal
    const [isEditOpened, setIsEditOpened] = useState<boolean>(false);
    const [selectedStudent, setSelectedStudent] = useState<StudentModel>(nilStudent);
    const onEditPageSave = (student : StudentModel) => {
        const all = allStudents.map(s => s.authUser.id === student.authUser.id ? student : s);
        const single = allStudents.map(s => s.authUser.id === student.authUser.id ? student : s);

        setStudents(all.sort((s1, s2) => s1.firstName + s1.lastName < s2.firstName + s2.lastName ? -1 : 1));
        setStudents(single.sort((s1, s2) => s1.firstName + s1.lastName < s2.firstName + s2.lastName ? -1 : 1));

        onEditPageClose();
    }
    const onEditPageClose = () => {
        setIsEditOpened(false);
    }

    const onEditOpen = (student : StudentModel) => {
        setIsEditOpened(true);
        setSelectedStudent(student);
    }

    // Add Student Modal
    const [isAddOpened, setIsAddOpened] = useState<boolean>(false);
    const onAddPageClose = () => {
        setIsAddOpened(false);
    }
    const onAddPageOpen = () => {
        setIsAddOpened(true);
    }
    const onAddPageSave = (student : StudentModel) => {

        allStudents.push(student);
        setAllStudents(allStudents);
        setStudents(allStudents);
        onAddPageClose();
    }

    /***Delete Student****/
    const onDeleteStudent = (student : StudentModel) => {
        if(window.confirm('Are you sure you want to delete ' + student.firstName + ' ' + student.lastName)) {
            coreContext.setLoading(true);
            UserService.deleteUser(student).then(() => {
                setAllStudents(allStudents.filter((s) => s.authUser.id !== student.authUser.id));
                setStudents(students.filter((s) => s.authUser.id !== student.authUser.id))
            }).catch((err) => {
                logAllErrors(err);
            }).finally(() => {
                coreContext.setLoading(false);
            })
        }
    }

    /**Reset password for a student**/
    const onResetPassword = (student : StudentModel) => {
        coreContext.setLoading(true);
        UserService.resetPassword(student).then(() =>
            logSuccess('Password for ' + student.firstName + 'was reset successfully')
        )
        .catch(err =>logAllErrors(err))
        .finally(() => coreContext.setLoading(false));
    }


    /*****Rendering Helpers*****/

    const renderStudent = (student : StudentModel, index : number) => {
        return (
            <tr onClick={() => {onEditOpen(student)}} key={"tr"+index}>
                <td>{ index + 1 }</td>
                <td>{ student.firstName + ' ' + student.lastName }</td>
                <td>{ student.nickName || '___' }</td>
                <td>{ student.gender }</td>
                <td>{ student.birthdate.toLocaleDateString() }</td>
                <td>{ student.authUser.email }</td>
                <td>{ student.facebookUrl }</td>
                <td>{ student.school || '___'}</td>
                <td onClick={(e) => { e.stopPropagation(); onResetPassword(student)}}><Loop color="primary"/></td>
                <td onClick={(e) => { e.stopPropagation(); onDeleteStudent(student)}}><Delete color="secondary"/></td>
            </tr>
        );
    }
    const renderStudents = ()  => {
         return students.map(renderStudent);
    }
    const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const needle = event.target.value.toLowerCase();
        if(!needle || needle.length === 0)
            setStudents(allStudents);
        else
            setStudents(allStudents.filter(student => 
                (student.firstName + ' ' +student.lastName).toLowerCase().includes(needle) ||
                student.nickName?.toLowerCase().includes(needle) || 
                student.authUser.email.toLowerCase().includes(needle)
            ))
    }

    /****Render****/
    return (
        <div className="list-container">
            
            <ModalComponent closeModal={onEditPageClose} isOpen={isEditOpened}><EditStudentPage title="Edit Student" serviceFunc={UserService.updateStudent} genders={allGenders} student={selectedStudent} onSave={onEditPageSave} onClose={onEditPageClose}/></ModalComponent>
            <ModalComponent closeModal={onAddPageClose} isOpen={isAddOpened}><EditStudentPage title="Add new Student" serviceFunc={UserService.addStudent}  genders={allGenders} student={nilStudent} onSave={onAddPageSave} onClose={onAddPageClose}/></ModalComponent>
            <div className="page-header">
                <h2 className="mytitle">Manage Students</h2>
            </div>
            <div className="student-searchbar">
                <SearchBarComponent onChange={onSearch}/>
            </div>
            <div className="students-table-container">
                <table className="students-table">
                    <thead>
                        <tr>
                            <th><Person/></th>
                            <th>List of Students</th>
                            <th>Nick Name</th>
                            <th>Gender</th>
                            <th>Birthdate</th>
                            <th>Email</th>
                            <th>Facebook Account Name</th>
                            <th>School</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderStudents()}
                    </tbody>
                </table>
            </div>
            <div className="add-student-button">
                <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<Add />}
                        onClick={() => onAddPageOpen()}
                    >
                        Add
                </Button>
            </div>
        </div>
    )
}


export default StudentsPage;
