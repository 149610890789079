import { Button } from '@material-ui/core';
import { GetApp, School, Send } from '@mui/icons-material';
import React, { useState, useContext } from 'react';
import { PacmanLoader } from 'react-spinners';
import OutlinedDatePicker from '../../../basic-components/outlined-datepicker/outlined-datepicker';
import OutlinedSelect from '../../../basic-components/outlined-select/outlined-select';
import { purpleColor } from '../../../constants';
import { ClassModel, nilClass } from '../../../models/classes';
import { EsrModel, GenerateReportRequestModel } from '../../../models/esr';
import ClassesService from '../../../services/classes.services';
import EsrService from '../../../services/esr.services';
import CoreContext from '../../../state/contexts/core-context';
import { logAllErrors, logSuccess, reduce } from '../../../utils/utils';


interface LoadingProgress {
    classes : boolean;
    esrRecords: boolean;
}

export default function EsrPage() {
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const [esrRecords, setEsrRecords] = useState<Array<EsrModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    
    const [generateReportRequest, setGenerateReportRequest] = useState<GenerateReportRequestModel>({
        class_: nilClass,
        startDate: new Date(),
        endDate: new Date()
    })
    
    const coreContext = useContext(CoreContext); 

    /***********Helpers*************/
    const genClassName = (class_ : ClassModel) => class_.classCategory.name + ' -- ' +class_.className;


    /*******Initialization*******/
    const serverDataSettersCollection = {
        'classes': setClasses,
        'esrRecords': setEsrRecords
    }

    
    React.useEffect(() => {
        const loadingProgress : LoadingProgress = {
            'classes': false,
            'esrRecords': false
        };

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        });
        
        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 
        coreContext.setLoading(true);
        
        ClassesService.fetchAllClasses().then((classes) => {
            // sort the classes by class category and name 
            classes.sort((c1, c2) => genClassName(c1) < genClassName(c2) ? -1 : 1);
            handleFetchedData('classes', classes);
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
        }).finally(stopLoadingIfAllDone);

        const fetchEsr = () => {
                EsrService.fetchAllReports().then((reports: Array<EsrModel>) => {
                console.log(reports)
                handleFetchedData('esrRecords', reports)
            }).catch(err => {
                logAllErrors('Could not load the generated reports');
            }).finally(stopLoadingIfAllDone)
            setTimeout(fetchEsr, 30000)
        }

        fetchEsr()
         // eslint-disable-next-line
    }, []);

    const calculateDateDiff = (date1: Date, date2: Date) => {
        return ((date1.getFullYear() - date2.getFullYear()) * 31557600000) + ((date1.getMonth() - date2.getMonth()) * 2629800000) + ((date1.getDate() - date2.getDate()) * 86400000) + ((date1.getUTCHours() - date2.getUTCHours()) * 3600000) + ((date1.getUTCMinutes() - date2.getUTCMinutes()) * 60000)
    }
    const getStyleBasedOnDate = (date: Date) => {
        const now = new Date()
        const diff = calculateDateDiff(now, date)
        return diff <= 1800000 ? { color: '#257c1b' } : {}
    }

    const requestReportGeneration = () => {
        setLoading(true)
        EsrService.requestReportGeneration(generateReportRequest).then((res) => {
            logSuccess("Report Generation is taking place. It may take over to 10 minutes depending on the size of the class. Please refresh the page after 10 minutes");
        })
        .catch(err => {
            logAllErrors(err)
        }).finally(() => setLoading(false))
    }

    /*** render ***/
    const renderReports = () => {
        const classIdMap = new Map<number, ClassModel>()
        classes.forEach(class_ => classIdMap.set(class_.id, class_))
        esrRecords.sort((r1, r2) => calculateDateDiff(r2.publishedDate, r1.publishedDate))
        const printDate = (date: Date) => date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        return esrRecords
            .filter(record => classIdMap.has(record.classId))
            .map((record, index) => (
                <tr key={"tr" + index} style={getStyleBasedOnDate(record.publishedDate)}>
                    <td>{ index + 1 }</td>
                    <td>{ genClassName(classIdMap.get(record.classId) || nilClass) }</td>
                    <td>{ printDate(record.startDate) }</td>
                    <td>{ printDate(record.endDate) }</td>
                    <td>{ printDate(record.publishedDate) }</td>
                    <td>{ record.publishedDate.toLocaleTimeString() }</td>
                    <td onClick={async () => await EsrService.downloadReport(record)}><GetApp/></td>
                </tr>
            ))
    }

    const setGenerateReportRequestValue = (key: keyof GenerateReportRequestModel, val: ClassModel | Date) => { 
        setGenerateReportRequest({...generateReportRequest, [key]: val})
    }

    return (
        <div className="list-container">
        <h2>Please Select a Class and a Period</h2>
            <div className="compose-email-container">
                <div className="select-class-email-container">
                    <OutlinedSelect  label="Choose Class"
                        items={classes.map(c => c.classCategory.name + '---' + c.className)}
                        values={classes.map(c=> c.id)}
                        optional={true}
                        onChange={(e) =>
                            setGenerateReportRequestValue(
                                'class_',
                                 classes.find((c) => c.id === e.target.value) || nilClass,
                            )
                        }
                        value={generateReportRequest.class_.id || 0}
                    />
                    <OutlinedDatePicker label="From Date" value={generateReportRequest.startDate} onChange={(date) => setGenerateReportRequestValue('startDate', date || generateReportRequest.startDate)}/>
                    <OutlinedDatePicker label="To Date" value={generateReportRequest.endDate} onChange={(date) => setGenerateReportRequestValue('endDate', date || generateReportRequest.endDate)}/>
                    {loading ? (<div className="ecr-loader"><PacmanLoader size={15} color={purpleColor}/></div>) : (null)}
                    <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<Send />}
                        onClick={requestReportGeneration}
                        >
                            Generate
                    </Button>
                </div>
            </div>
            <table className="students-table">
                <thead>
                    <tr>
                        <th><School/></th>
                        <th>Class Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Publication date</th>
                        <th>Publication Time</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    { renderReports() }
                </tbody>
            </table>
        </div>
    )
}