import React, { useContext, useState } from 'react';
import './compose-email.css';
import ClassesService from '../../../services/classes.services';
import { logAllErrors, logSuccess, reduce } from '../../../utils/utils';
import CoreContext from '../../../state/contexts/core-context';
import { ClassModel } from '../../../models/classes';
import Multiselect from 'multiselect-react-dropdown';
import { Button, TextField } from '@material-ui/core';
import { ClassesEmail } from '../../../models/emails';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {convertToRaw} from 'draft-js'
import ComposeEmailTemplate from './email-template/email-template';
import { PacmanLoader } from 'react-spinners';
import { Send } from '@mui/icons-material';
import { purpleColor } from '../../../constants';
import ComposeEmailService from '../../../services/compose-email.service';

const allClassesId = -12314134141234;

interface ClassSummarized {
    name : string;
    id : number;
};

export default function ComposeEmailPage() {
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const [classesOptions, setClassesOptions] = useState<Array<ClassSummarized>>([]);
    const [editorState, setEditorState] = useState<EditorState>();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<ClassesEmail>(new ClassesEmail());
    
    /*******Handle State Change*******/
    const onEmailAttrChange = (key : keyof ClassesEmail, val : string | Set<ClassModel>) => {
        setEmail({...email, serialize: email.serialize, [key]: val});
    }

    const onBodyChange = (body : EditorState) => {
        const msgBody : string = draftToHtml(convertToRaw(body.getCurrentContent()));
        onEmailAttrChange('body', msgBody);
        setEditorState(body);
    }


    const coreContext = useContext(CoreContext);

    
    /****Creates array of ClassSummerized objects from the given classes*****/
    const summerizeClasses = (classes : Array<ClassModel>) => 
        [{name: 'Select All Classes', id: allClassesId}].concat(classes.map(c => {return {
            name: c.classCategory.name + ' ---- ' + c.className,
            id: c.id,
        }}));
    
    /*********** Initialization **************/
    React.useEffect(() => {
        interface LoadingProgress {
            classes : boolean;
        }
        const loadingProgress : LoadingProgress = {
            'classes': false,
        };
        const serverDataSettersCollection = {
            'classes': setClasses,
        }

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        });
        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 
        coreContext.setLoading(true);
        ClassesService.fetchAllClasses().then((classes) => {
            handleFetchedData('classes', classes);
            setClassesOptions(summerizeClasses(classes));
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
        }).finally(stopLoadingIfAllDone);
         // eslint-disable-next-line
    }, []);

    /*************Handle select classes change**************/
    const onSelectNewClass = (sumList : Array<ClassSummarized>, classSum : ClassSummarized) => {
        
        /******If Select All********/
        if(classSum.id === allClassesId) {
            onEmailAttrChange('classes', new Set(classes.slice(0)));
            sumList.splice(0, sumList.length);
            sumList.push(classSum);
            return;
        }
        else if(sumList.find(c => c.id === allClassesId)) {
            sumList.splice(sumList.indexOf(classSum), 1);
            return;
        }

        /****If Single Class selected****/
        const class_ = classes.find(c => c.id === classSum.id);

        if(class_){
            const newSelected = new Set(email.classes);
            newSelected.add(class_)
            onEmailAttrChange('classes', newSelected);
        }
    }

    const onRemoveSelectedClass = (sumList : Array<ClassSummarized>, classSum : ClassSummarized) => {
        if(classSum.id === allClassesId) {
            sumList.splice(0, sumList.length);
            onEmailAttrChange('classes', new Set());
            return;
        }
        const class_ = classes.find(c => c.id === classSum.id);
        if(class_) {
            const newSelected = new Set(email.classes);
            newSelected.delete(class_);
            onEmailAttrChange('classes', newSelected);
        }
    }

    /*********API Call*********/
    const sendEmail = () => {
        setLoading(true);

        ComposeEmailService.sendEmailToClasses(email).then(() => {
            logSuccess('The email was sent successfully to the selected Classes');
        })
        .catch((err) => logAllErrors(err))
        .finally(() => setLoading(false));
    }

    /**********Render**********/
    return (
        <div className="list-container">
            <h2>Please Select a Class</h2>
            
            <div className="compose-email-container">
                <div className="select-class-email-container">
                    <Multiselect
                        placeholder="Select Recipients"
                        options={classesOptions} // Options to display in the dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        onSelect={onSelectNewClass}
                        onRemove={onRemoveSelectedClass}
                    />
                </div>
                <div className="email-subject-container">
                    <TextField
                        error={email.subject === ''}
                        fullWidth
                        label="Email Subject"
                        value={email.subject}
                        variant='outlined'
                        onChange={(e) => onEmailAttrChange('subject', e.target.value)}
                        size="small"
                    />
                </div>
                <div className="email-body-container">
                    <Editor
                                    editorState={editorState}
                                    toolbarClassName="html-tool-bar"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="html-editor"
                                    onEditorStateChange={onBodyChange}
                    />
                </div>
                <div className="ecr-send-reports-button">
                        {loading ? (<div className="ecr-loader"><PacmanLoader size={15} color={purpleColor}/></div>) : (null)}
                        <div className="ecr-button">
                            <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<Send />}
                            onClick={sendEmail}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
            </div>

            <div className="compose-email-preview-container">
                <h2>Preview</h2>
                <ComposeEmailTemplate>
                    {email.body}
                </ComposeEmailTemplate>
            </div>
        </div>
    );
}