import React from 'react';
import './three-state-tickbox.css';
import ArchiveRecordInputProps from '../props-interface';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
export default function ThreeStateTickbox(props : ArchiveRecordInputProps) {

    const onToggle = () => {
        const newRecord = props.record.create_same_type_obj({student: props.record.student});
        if(!props.record.isDefinedVal())
            newRecord.setRecordValue(true, undefined);
        else if(props.record.boolVal())
            newRecord.setRecordValue(false, undefined);
        else
            newRecord.setRecordValue();
        props.setRecordValue(newRecord);
    }
    const renderIcon = () => {
        if(!props.record.isDefinedVal()) return (null);
        if(props.record.boolVal()) return (<Check style={{color: '#06a001'}}/>)
        return (<Close style={{color: '#d3040e'}} />)
    } 
    return (
        <div className='outer-three-tick-container' onClick={onToggle}>
            <div className='inner-container'>
                {renderIcon()}
            </div>
            <div className='three-tick-line'>
            </div>
        </div>
    );
}