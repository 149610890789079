import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { ClassModel } from '../models/classes';
import { nilRankingModel, RankingModel } from '../models/ranking';

setAuthOnAxios(Axios);


const RankingService = {
    async fetchRankings(class_ : ClassModel) : Promise<RankingModel>{
        const url = settings.baseUrl + 'api/ranking/all/' + class_.id;
        var res = null;
        try {
            res = await Axios.get(url);
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            window.location.reload();
            console.log(err.response);
            return nilRankingModel;
        }
        try {
            console.log(res.data);
            return new RankingModel(res?.data)
        } catch(err) {
            console.log(err);
            throw new Error('Unexpected response format');
        }
    },
    async updateIsRankedState(class_: ClassModel): Promise<void> {
        const url = settings.baseUrl + 'api/ranking/update-freeze-ranking/' + class_.id;
        try {
            await Axios.put(url, {is_ranked: class_.isRanked});
        } 
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
                console.log(err.response);
            }
            throw err.response.data
        }
    }
}
export default RankingService;