import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


interface OutLinedSelectionProps {
    optional : boolean;
    value : number | string;
    label : string;
    items : Array<string>;
    values? : Array<number|string>;
    onChange : (event: React.ChangeEvent<{ value: unknown }>) => void;
};

export default function OutlinedSelect(props : OutLinedSelectionProps) {
    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.value}
                label={props.label}
                onChange={props.onChange}        
                fullWidth
            >
                {props.optional? 
                
                    (<MenuItem key={props.label + '_None'} value={''}>None</MenuItem>)
                    :
                    null
                } 
                {props.items.map((item, index) => (<MenuItem key={props.label + index} value={props.values? props.values[index] : item}>{item}</MenuItem>))}
            </Select>
        </FormControl>
    )
}