/**
 *  https://www.youtube.com/watch?v=udr2rx_B99w
 *  In this file, we combine all the reducers together
 */

import { combineReducers } from 'redux';
import  AuthReducer  from './auth.reducer';

const reducers = combineReducers({
    auth: AuthReducer
});

export default reducers;