import { checkAllRequiredFields } from "../utils/utils";
import { ClassModel } from "./classes";
import { DateModel } from "./Date";

export interface GenerateReportRequestModel {
    class_: ClassModel;
    startDate: Date;
    endDate: Date;
}

export class EsrModel {
    classId : number;
    zipPath : string;
    publishedDate : Date;
    startDate: Date;
    endDate: Date;

    constructor(obj: any) {
        checkAllRequiredFields(obj, ['class_id', 'zip_path', 'published_date', 'start_date', 'end_date']);

        this.classId = obj['class_id'];
        this.zipPath = obj['zip_path'];
        this.publishedDate = new Date(obj['published_date']);
        this.startDate = new DateModel(obj['start_date']);
        this.endDate = new DateModel(obj['end_date']);
    }
}

