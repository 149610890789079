import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Add, Remove } from '@mui/icons-material';
import React from 'react';
import OutlinedSelect from '../../../../../basic-components/outlined-select/outlined-select';
import OutlinedTimePicker from '../../../../../basic-components/outlined-timepicker/outlined-timepicker';
import { ClassModel, SessionModel } from '../../../../../models/classes';
import './sessions.css';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thurday', 'Friday', 'Saturday', 'Sunday'];


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);

interface SessionsProps {
    class_ : ClassModel;
    setClass : (class_ : ClassModel) => void;
}
function SessionsComponent(props : SessionsProps) {
    const classes = useStyles();

    /*****state Handlers*****/
    const addSession = () => {
        const sessions = props.class_.sessions;
        sessions.push({
            day: 0,
            time: new Date()
        });
        props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, sessions});
    }
    const removeSession = () => {
        props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, sessions: props.class_.sessions.slice(0, props.class_.sessions.length - 1)});
    }

    /******Render Helper********/
    const renderSession = (session : SessionModel, index : number) => {
        return (
            <div className="session-div" key={"session-div" + index}>
                <div className="session-day">
                    <OutlinedSelect optional={false} onChange={(e) => {
                        if(e.target.value) {
                            const sessions = props.class_.sessions;
                            sessions[index].day = weekdays.indexOf(e.target.value as string);
                            props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, sessions: sessions});
                        }
                    }} items={weekdays} label='Weekday' value={weekdays[session.day]} />
                </div>
                <div className="session-time">
                    <OutlinedTimePicker onChange={(date) => {
                        if(date) {
                            const sessions = props.class_.sessions;
                            sessions[index].time = date;
                            props.setClass({...props.class_, lastSessionDate: props.class_.lastSessionDate, sessions: sessions});
                        }
                    }} title="Session Time" time={session.time}/>
                </div>
            </div>
        )
    }
    const renderSessions = () => props.class_.sessions.map((session, index) => renderSession(session, index));
    /******Render******/
    return (
        <div>
            <h3>Sessions</h3>
                {renderSessions()}
            <div className="add-session-button">
            <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={addSession}
                ><Add/></Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    onClick={removeSession}
                ><Remove/></Button>
            </div>
        </div>
    )
}

export default SessionsComponent;
