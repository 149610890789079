import settings from '../settings.json';
import Axios from 'axios';
import { nilStudent, serializeStudent, serializeUser, StudentModel, UserModel } from '../models/users';
import { setAuthOnAxios } from './auth.service';

setAuthOnAxios(Axios);



const UsersService = {
    async fetchAllStudents() : Promise<Array<StudentModel>> {
        const url = settings.baseUrl + 'api/user/students/all';
        var res = null;
        try {
            res = await Axios.get(url);
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            window.location.reload();
            console.log(err.response);
            return [];
        }
        try {
            return res?.data.map((value : any, index : number) => new StudentModel(value));
        } catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },
    async fetchAllTeacher() : Promise<Array<UserModel>> {
        const url = settings.baseUrl + 'api/user/teacher/all';
        var res = null;
        try {
            res = await Axios.get(url);
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            window.location.reload();
            console.log(err.response);
            return [];
        }
        try {
            return res?.data.map((value : any, index : number) => new UserModel(value));
        } catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },

    async updateStudent(student : StudentModel) : Promise<StudentModel> {
        const url = settings.baseUrl + 'api/user/students/admin-update/' + student.authUser.id;
        var res = null;
        try {
            console.log(serializeStudent(student));
            res = await Axios.put(url, serializeStudent(student));
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            else {
                console.log(err.response?.data);
                throw err.response?.data;
            }
            window.location.reload();
            console.log(err.response);
            return nilStudent;
        }
        try {
            res.data['auth_user'] = {
                id: res.data['id'],
                email: res.data['email'],
            };
            return new StudentModel(res.data);
        }
        catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },
    async addStudent(student : StudentModel) : Promise<StudentModel> {
        const url = settings.baseUrl + 'api/user/student/register';
        var res = null;
        try {
            console.log(serializeStudent(student));
            res = await Axios.post(url, serializeStudent(student));
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            else {
                console.log(err.response?.data);
                throw err.response?.data;
            }
            window.location.reload();
            console.log(err.response);
            return nilStudent;
        }
        try {
            res.data['auth_user'] = {
                id: res.data['id'],
                email: res.data['email'],
            };
            return new StudentModel(res.data);
        }
        catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },
    async addTeacher(teacher : UserModel) : Promise<UserModel> {
        const url = settings.baseUrl + 'api/user/teacher/register';
        var res = null;
        try {
            console.log(serializeStudent(teacher));
            res = await Axios.post(url, serializeUser(teacher));
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            else {
                console.log(err.response?.data);
                throw err.response?.data;
            }
            window.location.reload();
            console.log(err.response);
            return nilStudent;
        }
        try {
            res.data['auth_user'] = {
                id: res.data['id'],
                email: res.data['email'],
            };
            return new UserModel(res.data);
        }
        catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },
    async updateTeacher(teacher : UserModel) : Promise<UserModel> {
        const url = settings.baseUrl + 'api/user/teacher/update';
        var res = null;
        try {
            res = await Axios.put(url, serializeStudent(teacher));
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            else {
                console.log(err.response?.data);
                throw err.response?.data;
            }
            window.location.reload();
            console.log(err.response);
            return nilStudent;
        }
        try {
            res.data['auth_user'] = {
                id: res.data['id'],
                email: res.data['email'],
            };
            return new UserModel(res.data);
        }
        catch(err) {
            console.error(err);
            throw new Error('Unexpected response format');
        }
    },
    async deleteUser(user : UserModel) : Promise<void> {
        const url = settings.baseUrl + 'api/user/delete/';
        try {
            await Axios.delete(url + user.authUser.id);
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
            }
            else {
                console.log(err.response?.data);
                throw err.response?.data;
            }
            window.location.reload();
            console.log(err.response);
        }
    },
    async resetPassword(user : UserModel) : Promise<void> {
        const url = settings.baseUrl + 'api/user/reset-password-request';
        const data = {
          email: user.authUser.email
        };
        try {
            await Axios.post(url, data);
        }
        catch(err) {
            console.log(err);
            throw err;
        }
    }
}


export default UsersService;