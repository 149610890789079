import { useContext, useEffect, useState } from 'react';
import { ArchiveModel, ArchiveRecordModel, EntityModel, nilArchive } from '../../models/archive'
import { ClassModel } from '../../models/classes';
import { StudentModel } from '../../models/users';
import ArchiveService from '../../services/archive.service';
import CoreContext from '../../state/contexts/core-context';
import { concatenateAllMaps, logAllErrors } from '../../utils/utils';


type ArchiveProps = {
    class_: ClassModel | undefined,
    startDate: Date,
    endDate: Date
}
type Record = { entityId: number, entityName: string, recordVal: string }

export type StudentRecords = {
    studentId: number,
    studentName: string,
    homeworkIdsToVal: Map<number, string>,
    quizzesIdsToVal: Map<number, string>,
    examsIdsToVal: Map<number, string>,
    attendanceIdsToVal: Map<number, string>,
    // This works because an entity id is unique among all entities since they inherit the same base class
    allEntitiesIdsToVal: Map<number, string>
}

type ArchiveHook = {
    isArchiveLoading: boolean,
    archive?: ArchiveModel,
    allStudentsRecords: StudentRecords[]
}

declare type ToString<T> = (val: T) => string;

const makeStudentToEntityRecordsMap = <R extends ArchiveRecordModel, T extends EntityModel<R>>(entities: T[], valueToString: ToString<R>): Map<number, Record[]> => {
    const map = new Map<number, Record[]>()

    entities.forEach((homework => {
        homework.records.forEach((record) => {
            const oldArr: Record[] = map.get(record.student) || [];
            oldArr.push({entityId: homework.id, entityName: homework.name, recordVal: valueToString(record) });
            map.set(record.student, oldArr);
        })
    }))

    return map;
}


const constructStudentsRecord = <T extends ArchiveRecordModel>(student: StudentModel, entities: EntityModel<T>[], studentMap: Map<number, Record[]>): Map<number, string> => {
    const result = new Map<number, string>();

    entities
        .map((entity) => studentMap.get(student.authUser.id)?.find((record) => record.entityId === entity.id) || { entityId: entity.id, entityName: entity.name, recordVal: 'NA' } )
        .forEach(record => result.set(record.entityId, record.recordVal));

    return result;
}



const constructAllStudentRecords = (class_: ClassModel, archive: ArchiveModel): StudentRecords[] => {
    const studentToHomework = makeStudentToEntityRecordsMap(archive.homeworks, (homework) => homework.boolVal() ? 'YES' : 'YES')
    const studentToAttendance = makeStudentToEntityRecordsMap(archive.attendances, (attendance) => attendance.boolVal() ? 'YES' : 'NO')
    
    const studentToQuizzes = makeStudentToEntityRecordsMap(archive.quizzes, (quiz) => `${quiz.numVal()} / ${quiz.maxVal()}`)
    const studentToExams = makeStudentToEntityRecordsMap(archive.exams, (exam) => `${exam.numVal()} / ${exam.maxVal()}`)
    
    return class_.students.map((student) => {
        const homeworkIdsToVal = constructStudentsRecord(student, archive.homeworks, studentToHomework)
        const quizzesIdsToVal = constructStudentsRecord(student, archive.quizzes, studentToQuizzes)
        const examsIdsToVal = constructStudentsRecord(student, archive.exams, studentToExams)
        const attendanceIdsToVal = constructStudentsRecord(student, archive.attendances, studentToAttendance)
    
        return {
            studentId: student.authUser.id,
            studentName: `${student.firstName} ${student.lastName}`,
            allEntitiesIdsToVal: concatenateAllMaps([homeworkIdsToVal, quizzesIdsToVal, examsIdsToVal, attendanceIdsToVal]),
            homeworkIdsToVal,
            quizzesIdsToVal,
            examsIdsToVal,
            attendanceIdsToVal
        }
    });
}


const filterArchiveByDate = (archive: ArchiveModel, fromDate: Date, toDate: Date): ArchiveModel => ({ 
    ...archive,
    homeworks: archive.homeworks.filter(homework => homework.date >= fromDate && homework.date <= toDate),
    quizzes: archive.quizzes.filter(quizzes => quizzes.date >= fromDate && quizzes.date <= toDate),
    exams: archive.exams.filter(exams => exams.date >= fromDate && exams.date <= toDate),
    attendances: archive.attendances.filter(attendances => attendances.date >= fromDate && attendances.date <= toDate),
})

export const useArchive = ({ class_, startDate, endDate } : ArchiveProps): ArchiveHook  => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [archive, setArchive] = useState<ArchiveModel>(nilArchive);

    const coreContext = useContext(CoreContext)
    
    useEffect(() => {
        if(!class_) {
            return 
        }
        coreContext.setLoading(true);
        setIsLoading(true);

        ArchiveService.fetchAllArchiveEntities(class_)
        .then(setArchive)
        .catch(logAllErrors)
        .finally(() => {
            coreContext.setLoading(false)
            setIsLoading(false)
        });    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [ class_ ])

    if(!class_) {
        return {
            isArchiveLoading: isLoading,
            archive: undefined,
            allStudentsRecords: []
        }
    }

    const filteredArchive = filterArchiveByDate(archive, startDate, endDate)
    
    return {
        isArchiveLoading: isLoading,
        archive: filteredArchive,
        allStudentsRecords: constructAllStudentRecords(class_, filteredArchive)
    }
}