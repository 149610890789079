import React from 'react';

interface CoreContextInterface { setLoading : Function };
const CoreContext = React.createContext<CoreContextInterface>({
    setLoading : () => { }
});

export const CoreProvider = CoreContext.Provider;

export default CoreContext;
