import React, {useState} from 'react';
import './login.css';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AuthService from '../../services/auth.service';
import {logAllErrors, logSuccess} from '../../utils/utils';
import BeatLoader from "react-spinners/BeatLoader";
import {purpleColor} from '../../constants';

const useStyles = makeStyles({
    submitButton: {
        background: '#ca0000',
        color: 'white',
        width: '50%',
        height: '7vh',
        '&:hover': {
            background: '#e53042',
        }
    }
});

interface LoginState {
    email : string;
    password: string;
    showPassword : boolean;
    emailError : string
    passwordError : string
    loading : boolean
}

function LoginPage(props : any){

    const classes = useStyles();
    const [values, setValues] = useState<LoginState>({
        email: '',
        password: '',
        showPassword: false,
        emailError: '',
        passwordError: '',
        loading: false
    });

    ////////////////////////////////////////////////
    // Input handlers 
    //
    // Functions that handles input from the user
    ////////////////////////////////////////////////
    const handleChange = (key : keyof LoginState) => (event : React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [key]: event.target.value, emailError: '', passwordError: ''});
    }
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleEnterPressOnTextField = (e : any) => {
        if(e.keyCode === 13)
            onLogin();
    }
    
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    /////////////////////////////////////////////////////
    // Validation functions
    //
    // functions that does validation on the user input
    ////////////////////////////////////////////////////
    /**
     * adopted from https://stackoverflow.com/a/46181 
     * @param email email string to be checked
     * @brief checks if the given email is valid
     * @returns true if the email format is valid, false otherwise
     */
    const validateEmail = (email : string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    /**
     * @brief does validations on the input email and password. The email must not be empty
     *        and must be of an email format. The password must not be empty.
     * @effect writes error messages to the state of this component on errors
     * @modifies this state
     * @returns boolean --- true if all the validations succeeds, false otherwise
     */
    const validateInput = () : boolean => {
        var emailError = '';
        var passwordError = '';

        // validate email address
        if(values.email === '') 
            emailError = 'Email field may not be empty';
        else if(!validateEmail(values.email))
            emailError = 'The email address does not look right';

        // validate password
        if(values.password === '') 
            passwordError = 'Password field may not be empty';
        
        setValues({...values, emailError: emailError, passwordError: passwordError});

        return emailError === '' && passwordError === '';
    };


    /**
     * @brief logins with the email and password given by the user if they passes the
     *        validations.
     * @effects writes authentication token to the store. Redirects the user on successful login to 
     *          the dashboard
     * @modifies persistent store
     */
    const onLogin = () => {
        if(!validateInput()) return;
        
        setValues({...values, loading: true});
        AuthService.login(values.email, values.password).then(msg => {
            logSuccess(msg);
            setValues({...values, loading: false});
            setTimeout(() => { window.location.reload(); }, 500);
        }).catch(err => {
            logAllErrors(err);
            setValues({...values, loading: false});
        })
    };
    ///////////////////////////////////
    // HTML
    ///////////////////////////////////
    return (
    <div className="Login-container">
        <div className="Login-box">
            <div className="Box-left-section">
                <h2 className="title">Login</h2>
                <h4 className="prompt">Sign in using your privileged account</h4>
                <div className="Textboxes">
                    <div className="Email-textbox">
                        <TextField
                            error={values.emailError !== '' ? true : false}
                            label="Email"
                            variant="outlined"
                            size="medium"
                            value={values.email}
                            onChange={handleChange('email')}
                            onKeyDown={handleEnterPressOnTextField}
                            fullWidth
                            helperText={values.emailError}
                        />
                    </div>
                    <div className="Password-textbox">
                        <TextField
                            error={values.passwordError !== '' ? true : false}
                            variant="outlined"
                            label="password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            size="medium"
                            fullWidth
                            helperText={values.passwordError}
                            onKeyDown={handleEnterPressOnTextField}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </div>
                    <div className="Submit-button-div">
                        <Button variant="contained" size="large" color="inherit" className="Submit-button" classes={{root: classes.submitButton}} onClick={onLogin}>
                            Login
                        </Button>
                    </div>
                    <BeatLoader color={purpleColor} loading={values.loading}/>
                </div>
            </div>
            <div className="Box-right-section">
                    <img className="logo-img" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/logo-inverse.png?alt=media&token=91355777-cc1c-4277-a591-b13405d81c85" alt="logo"/>
            </div>
        </div>
    </div>
    );
}

export default LoginPage;