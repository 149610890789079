import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

interface OutlinedTimePickerProps {
    title: string;
    time : Date;
    onChange : (_ : Date | null) => void;
}
export default function OutlinedTimePicker(props : OutlinedTimePickerProps) {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
            inputVariant='outlined'
            margin="normal"
            id="time-picker"
            label={props.title}
            value={props.time}
            onChange={props.onChange}
            KeyboardButtonProps={{
            'aria-label': 'change time',
            }}
        />
    </MuiPickersUtilsProvider>
  );
}
