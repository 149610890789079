import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface OutlinedDatePickerProps {
    label : string;
    value : Date;
    onChange : (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
}

export default function OutlinedDatePicker(props : OutlinedDatePickerProps) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            fullWidth
            inputVariant='outlined'
        />
</MuiPickersUtilsProvider>
    );
}