import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { EsrModel, GenerateReportRequestModel } from '../models/esr';
import { makeDateStr } from '../models/Date';
import FileDownload from 'js-file-download'

setAuthOnAxios(Axios);

const EsrService = {
    async fetchAllReports() : Promise<Array<EsrModel>>{
        const url = settings.baseUrl + 'api/esr/class-esr'
        var res = null;
        try {
            res = await Axios.get(url);
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
		window.location.reload();
            }
            console.log(err.response);
            return [];
        }
        try {
            return res?.data.map((value : any, index : number) => new EsrModel(value));
        } catch(err) {
            throw new Error('Unexpected response format');
        }
    },
    async requestReportGeneration(request: GenerateReportRequestModel): Promise<void>{
        const url = settings.baseUrl + 'api/esr/create-esr';
        try {
            await Axios.post(url, {
                class_id: request.class_.id,
                start_date: makeDateStr(request.startDate),
                end_date: makeDateStr(request.endDate)
            });
        }
        catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
		window.location.reload();
            } else {
	      throw  err.response?.data?.msg
	    }
            console.log(err.response);
            return;
        }
    },
    async downloadReport(esr: EsrModel): Promise<void>{
        try{
            const url = settings.baseUrl + 'api/esr/get-esr/' + esr.zipPath;
            console.log(url)
            Axios({
                url: url,
                method: 'GET',
                responseType: 'blob', // Important
              }).then((response) => {
                  FileDownload(response.data, esr.zipPath);
              });
        } catch(err) {
            if(err.response.status === 401) {
                localStorage.removeItem('token');
		window.location.reload();
            console.log(err.response);
            } else {
	      throw err
	    }
        }
    }
}

export default EsrService;
