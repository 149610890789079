import { useState, useEffect, useContext } from 'react';
import { ClassModel } from '../../models/classes';
import ClassesService from '../../services/classes.services';
import CoreContext from '../../state/contexts/core-context';
import { logAllErrors } from '../../utils/utils';


export type ClassesHookReturn = {
    classes: ClassModel[],
    classesAreLoading: boolean
}

export const useClasses = (): ClassesHookReturn => {
    const [state, setState] = useState<ClassesHookReturn>({
        classes: [],
        classesAreLoading: true
    });


    const coreContext = useContext(CoreContext); 
    
    useEffect(() => {
        ClassesService.fetchAllClasses().then((classes) => {
            setState({classesAreLoading: false, classes: classes})
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
            setState({ classesAreLoading: false, classes: [] })
        }).finally(() => coreContext.setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return state
}

