import React, {useContext, useState} from 'react';
import CoreContext from '../../../state/contexts/core-context';
import './teachers.css';
import UsersService from '../../../services/users.service';
import OptionsService from '../../../services/options.service';

import { logAllErrors } from '../../../utils/utils';
import {UserModel, nilUser } from '../../../models/users';
import Person from '@mui/icons-material/Person';
import SearchBarComponent from '../../../basic-components/searchbar/searchbar.component';
import { ChangeEvent } from 'react';
import ModalComponent from '../../../basic-components/modal/modal.component';
import EditStudentPage from './edit-teacher/edit-teacher';
import { GenderModel } from '../../../models/gender';
import UserService from '../../../services/users.service';
import  Button  from '@material-ui/core/Button';
import  Add  from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';

interface LoadingProgress {
    teachers : boolean;
    genders : boolean;
}; 
function TeachersPage()
{

    /*****Page Initialization******/
    const coreContext = useContext(CoreContext); 
    const [loaded, setLoaded] = useState<boolean>(false);
    const [teachers, setTeachers] = useState<Array<UserModel>>([]);
    const [allTeachers, setAllTeachers] = useState<Array<UserModel>>([]);
    const [allGenders, setAllGenders] = useState<Array<GenderModel>>([]);

    const serverDataSettersCollection = {
        'teachers': setAllTeachers,
        'genders': setAllGenders,
    }

    React.useEffect(() => {
        // this prevents the component of being rendered recursively 
        // every time coreContext.setLoading is called
        if(loaded) return;
        setLoaded(true);
        const loadingProgress : LoadingProgress = {
            'teachers': false,
            'genders': false,
        };
        const loadingStatus = () => { return !loadingProgress.genders || !loadingProgress.teachers }
        coreContext.setLoading(true);

        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
            coreContext.setLoading(loadingStatus());
        }


        /**Load All Students**/
        UsersService.fetchAllTeacher().then(teachers => {
            setTeachers(teachers);
            teachers.sort((s1, s2) => (s1.firstName + s1.lastName) < (s2.firstName + s2.lastName) ? -1 : 1);
            handleFetchedData('teachers', teachers);

        }).catch(err => {
            console.error(err);
            logAllErrors(err);
        });

        /**Load All Genders**/
        OptionsService.fetchAllGenders().then((genders) => {
           handleFetchedData('genders', genders);
        }).catch(err => {
            console.error(err);
            logAllErrors(err);
        });

        // eslint-disable-next-line
    }, [])



    /******** Modal Events ***********/
    // Edit Student Modal
    const [isEditOpened, setIsEditOpened] = useState<boolean>(false);
    const [selectedTeacher, setSelectedTeacher] = useState<UserModel>(nilUser);
    const onEditPageSave = (teacher : UserModel) => {
        const all = allTeachers.map(s => s.authUser.id === teacher.authUser.id ? teacher : s);
        const single = allTeachers.map(s => s.authUser.id === teacher.authUser.id ? teacher : s);

        setTeachers(all.sort((s1, s2) => s1.firstName + s1.lastName < s2.firstName + s2.lastName ? -1 : 1));
        setTeachers(single.sort((s1, s2) => s1.firstName + s1.lastName < s2.firstName + s2.lastName ? -1 : 1));

        onEditPageClose();
    }
    const onEditPageClose = () => {
        setIsEditOpened(false);
    }

    const onEditOpen = (teacher : UserModel) => {
        setIsEditOpened(true);
        setSelectedTeacher(teacher);
    }

    // Add Student Modal
    const [isAddOpened, setIsAddOpened] = useState<boolean>(false);
    const onAddPageClose = () => {
        setIsAddOpened(false);
    }
    const onAddPageOpen = () => {
        setIsAddOpened(true);
    }
    const onAddPageSave = (teacher : UserModel) => {

        allTeachers.push(teacher);
        setAllTeachers(allTeachers);
        setTeachers(allTeachers);
        onAddPageClose();
    }

    /***Delete Teacher****/
    const onDeleteStudent = (teacher : UserModel) => {
        if(window.confirm('Are you sure you want to delete ' + teacher.firstName + ' ' + teacher.lastName)) {
            coreContext.setLoading(true);
            UserService.deleteUser(teacher).then(() => {
                setAllTeachers(allTeachers.filter((s) => s.authUser.id !== teacher.authUser.id));
                setTeachers(teachers.filter((s) => s.authUser.id !== teacher.authUser.id))
            }).catch((err) => {
                logAllErrors(err);
            }).finally(() => {
                coreContext.setLoading(false);
            })
        }
    }

    /*****Rendering Helpers*****/

    const renderStudent = (teacher : UserModel, index : number) => {
        return (
            <tr onClick={() => {onEditOpen(teacher)}} key={"tr"+index}>
                <td>{ index + 1 }</td>
                <td>{ teacher.firstName + ' ' + teacher.lastName }</td>
                <td>{ teacher.nickName || '___' }</td>
                <td>{ teacher.gender }</td>
                <td>{ teacher.birthdate.toLocaleDateString() }</td>
                <td>{ teacher.authUser.email }</td>
                <td onClick={(e) => { e.stopPropagation(); onDeleteStudent(teacher)}}><Delete color="secondary"/></td>
            </tr>
        );
    }
    const renderStudents = ()  => {
         return teachers.map(renderStudent);
    }
    const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const needle = event.target.value.toLowerCase();
        if(!needle || needle.length === 0)
            setTeachers(allTeachers);
        else
            setTeachers(allTeachers.filter(teacher => 
                (teacher.firstName + ' ' +teacher.lastName).toLowerCase().includes(needle) ||
                teacher.nickName?.toLowerCase().includes(needle) || 
                teacher.authUser.email.toLowerCase().includes(needle)
            ))
    }

    /****Render****/
    return (
        <div className="list-container">
            
            <ModalComponent closeModal={onEditPageClose} isOpen={isEditOpened}><EditStudentPage title="Edit Teacher" serviceFunc={(UserService.updateTeacher)} genders={allGenders} teacher={selectedTeacher} onSave={onEditPageSave} onClose={onEditPageClose}/></ModalComponent>
            <ModalComponent closeModal={onAddPageClose} isOpen={isAddOpened}><EditStudentPage title="Add new Teacher" serviceFunc={UserService.addTeacher} genders={allGenders} teacher={nilUser} onSave={onAddPageSave} onClose={onAddPageClose}/></ModalComponent>
            <div className="page-header">
                <h2 className="mytitle">Manage Teachers</h2>
            </div>
            <div className="teacher-searchbar">
                <SearchBarComponent onChange={onSearch}/>
            </div>
            <div className="teachers-table-container">
                <table className="students-table">
                    <thead>
                        <tr>
                            <th><Person/></th>
                            <th>List of Students</th>
                            <th>Nick Name</th>
                            <th>Gender</th>
                            <th>Birthdate</th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderStudents()}
                    </tbody>
                </table>
            </div>
            <div className="add-student-button">
                <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<Add />}
                        onClick={() => onAddPageOpen()}
                    >
                        Add
                </Button>
            </div>
        </div>
    )
}


export default TeachersPage;
