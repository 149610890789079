import { AuthActionOption, AuthAction } from '../actions/auth.actions';
import { Dispatch } from 'redux';


export const storeToken = (token : string) => {
    return (dispatch : Dispatch<AuthAction>) => {
        dispatch({
            type: AuthActionOption.STORE_TOKEN,
            payload: token
        })
    }
}

export const removeToken = () => {
    return (dispatch : Dispatch<AuthAction>) => {
        dispatch({
            type: AuthActionOption.REMOVE_TOKEN
        })
    }
}