import React, { useState, useContext } from 'react';
import './ecr.css';
import {convertToRaw} from 'draft-js'
import { Editor, EditorState } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import CoreContext from '../../../state/contexts/core-context';
import { ClassModel } from '../../../models/classes';
import { logAllErrors, logSuccess, reduce } from '../../../utils/utils';
import ClassesService from '../../../services/classes.services';
import OutlinedSelect from '../../../basic-components/outlined-select/outlined-select';
import OutlinedDatePicker from '../../../basic-components/outlined-datepicker/outlined-datepicker';
import ECRTemplateComponent from './ecr-template/ecr-template';
import { ECRModel, NilECR } from '../../../models/ecr';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Send } from '@mui/icons-material';
import { Button } from '@material-ui/core';
import { purpleColor } from '../../../constants';
import ECRService from '../../../services/ecr.service';

interface LoadingProgress {
    classes : boolean;
}
export default function ECRPage() {
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const [showTempelate, setShowTemplate] = useState<boolean>(false);
    const coreContext = useContext(CoreContext); 
    const [loading, setLoading] = useState<boolean>(false);
    /***********Helpers*************/
    const genClassName = (class_ : ClassModel) => class_.classCategory.name + ' -- ' +class_.className;


    /*******Initialization*******/
    const serverDataSettersCollection = {
        'classes': setClasses,
    }

    React.useEffect(() => {
        const loadingProgress : LoadingProgress = {
            'classes': false,
        };

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        });
        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 
        coreContext.setLoading(true);
        ClassesService.fetchAllClasses().then((classes) => {
            // sort the classes by class category and name 
            classes.sort((c1, c2) => genClassName(c1) < genClassName(c2) ? -1 : 1);
            handleFetchedData('classes', classes);
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
        }).finally(stopLoadingIfAllDone);
         // eslint-disable-next-line
    }, []);

    /******** Handle Class Selection *********/
    const onSelectedClassChange = (e: React.ChangeEvent<{ value: unknown }>) => {

        /****Update State****/
        if(!e.target.value || e.target.value === '') return;
        const selectedClass = classes.find(c => c.id === e.target.value);
        if(!selectedClass) return;
        setEcr(new ECRModel(selectedClass, undefined, ''));
        setEditorState(undefined);
        setShowTemplate(true);
    }
    const [editorState, setEditorState] = useState<EditorState>();
    const [ecr, setEcr] = useState<ECRModel>(NilECR);


    const onBodyChange = (body : EditorState) => {
        const msgBody : string = draftToHtml(convertToRaw(body.getCurrentContent()));
        setEcr(new ECRModel(ecr.class_, ecr.date, msgBody));
        setEditorState(body);
    }
    const onDateChange = (date : Date | null) => {
        setEcr(new ECRModel(ecr.class_, date || ecr.date, ecr.body));
    }

    const sendReports = () => {
        setLoading(true);
        ECRService.sendReports(ecr).then(() => {
            logSuccess('Class Reports delivered to students of class ' + ecr.class_.className);
        })
        .catch(logAllErrors)
        .finally(() => setLoading(false));
    }
    /***************Render HTML Components******************/
    const renderECRForAClass = () => {
        if(!showTempelate) return (null);

        return (
            <div>
                <OutlinedDatePicker label="Report Date" value={ecr.date} onChange={(date) => onDateChange(date)}/>
                <div className="ecr-email-template-container">
                    <h3>The Email sent to the students will look similar to the template below</h3>
                    <h2>Please note that students will receive email only if they have records in the archive!</h2>
                    <ECRTemplateComponent ecr={ecr}>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="html-tool-bar"
                            wrapperClassName="wrapperClassName"
                            editorClassName="html-editor"
                            onEditorStateChange={onBodyChange}
                            />
                    </ECRTemplateComponent>
                    <div className="ecr-send-reports-button">
                        {loading ? (<div className="ecr-loader"><PacmanLoader size={15} color={purpleColor}/></div>) : (null)}
                        <div className="ecr-button">
                            <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<Send />}
                            onClick={sendReports}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="list-container">
            <div className="page-header">
                <h2 className="mytitle">Class Reports (ECR)</h2>
            </div>
            <OutlinedSelect  label="Choose Class" items={classes.map(genClassName)} values={classes.map(c=> c.id)} optional={true} onChange={onSelectedClassChange} value={ecr.class_.id}/>
            <div className='editor-div-container'>
                {renderECRForAClass()}
            </div>
        </div>

    );
}


