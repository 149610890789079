import React, {useContext, useState} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClassModel, nilClass } from '../../../models/classes';
import CoreContext from '../../../state/contexts/core-context';
import { autoGenerateNewEntityName, logAllErrors, logSuccess, reduce } from '../../../utils/utils';
import ClassesService from '../../../services/classes.services';
import './archive.css';
import OutlinedSelect from '../../../basic-components/outlined-select/outlined-select';
import ArchiveService from '../../../services/archive.service';
import { AppBar, Button, Tab, Tabs } from '@material-ui/core';
import SearchBarComponent from '../../../basic-components/searchbar/searchbar.component';
import { Add, Save } from '@mui/icons-material';
import ManageEntityPage from './manage-entity/manage-entity';
import ModalComponent from '../../../basic-components/modal/modal.component';
import { ArchiveModel, ArchiveRecordModel, createNilAttendace, createNilExam, createNilHomework, createNilQuiz, EntityModel, nilArchive, nilAttendanceRecord, nilExamRecord, nilHomeworkRecord, nilQuizRecord} from '../../../models/archive';
import EntityList from './entity-list/entity-list';
import {ExamGradesInputComponent, QuizGradesInputComponent} from '../../../basic-components/archive-record-input/grade-input/grade-input';
import ThreeStateTickBox from '../../../basic-components/archive-record-input/three-state-tickbox/three-state-tickbox';

type Props = {
    cat: string;
}
interface LoadingProgress {
    classes : boolean;
}

/********Those Arrays must be in the same order********/
const archiveTabs : Array<'Attendance'|'Homework'|'Quizzes'|'Exams'> = ['Attendance', 'Homework', 'Quizzes', 'Exams'];
const createNilEntity : Array<Function> = [createNilAttendace, createNilHomework, createNilQuiz, createNilExam];
const createEntityServiceFunc = [ArchiveService.createNewAttendance, ArchiveService.createNewHomework, ArchiveService.createNewQuiz, ArchiveService.createNewExam]
const updateEntityServiceFunc = [ArchiveService.updateAttendance, ArchiveService.updateHomework, ArchiveService.updateQuiz, ArchiveService.updateExam]
const archiveKeys : Array<keyof ArchiveModel> = ['attendances', 'homeworks', 'quizzes', 'exams'];
const archiveRecordFactory : Array<ArchiveRecordModel> = [nilAttendanceRecord, nilHomeworkRecord, nilQuizRecord, nilExamRecord];
const archiveRecordInputComponent = [ThreeStateTickBox, ThreeStateTickBox, QuizGradesInputComponent, ExamGradesInputComponent]; 
const singularEntityNames : Array<'Attendance'|'Homework'|'Quiz'|'Exam'> = ['Attendance', 'Homework', 'Quiz', 'Exam'];

/*****This is used for rendering the tabs*******/
function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
function ArchivePage ({ match }: RouteComponentProps<Props>) {
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const coreContext = useContext(CoreContext); 
    const [archive, setArchive] = useState<ArchiveModel>(nilArchive);
    const [classCategory, setClassCategory] = useState<string>('dfasdvxclkvn0-231p9fk;@');
    const [originalArchive, setOriginalArchive] = useState<ArchiveModel>(nilArchive);

    if(classCategory !== match.params.cat) {
        setClassCategory(match.params.cat);
    }

    
    /****Component Initialization****/
    const serverDataSettersCollection = {
        'classes': setClasses,
    }
    const resetAllState = () => {
        setArchive(nilArchive);
        setSelectedClass(undefined)
    }
    React.useEffect(() => {
        /***reset All the state first****/
        resetAllState();

        const loadingProgress : LoadingProgress = {
            'classes': false,
        };

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        });
        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 
        coreContext.setLoading(true);
        ClassesService.fetchAllClasses(match.params.cat).then((classes) => {
            handleFetchedData('classes', classes);
            console.log(classes);
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not load the classes');
        }).finally(stopLoadingIfAllDone);
         // eslint-disable-next-line
    }, [classCategory]);
    
    
    /*******Archive Entity Management*******/
    const removeEntityFromList = (entity : EntityModel<ArchiveRecordModel>) => {
        entity.removeFromArchive(archive);
        setArchive({...archive});
    }

    const updateEntityInList = (entity : EntityModel<ArchiveRecordModel>) => {
        entity.updateEntityInArchive(archive);
        setArchive({...archive});
    }
    /*********Add Archive entity***********/
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);

    const onAddOpen = () => setIsAddOpen(true);
    const onAddClose = () => setIsAddOpen(false);
    const onAddSaveSuccess = (savedEntity : EntityModel<ArchiveRecordModel>) => {
        savedEntity.insertIntoArchive(archive);
        setArchive({...archive});
    }

    /***Selected Class***/
    const [selectedClass, setSelectedClass] = useState<ClassModel>();
    const onSelectedClassChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if(!e.target.value || e.target.value === '') return;
        
        const selectedClass = classes.find(c => c.id === e.target.value);
        
        if(!selectedClass) return;

        setSelectedClass(selectedClass);
        coreContext.setLoading(true)
        ArchiveService.fetchAllArchiveEntities(selectedClass).then(archive => {
            setArchive(archive);
            setOriginalArchive(archive);
        }).finally(() => coreContext.setLoading(false));
    }

    /**
     * Tabs Management
     */
    const [tabIndex, setTabIndex] = useState<number>(0);
    const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };




    /***********************Send new Students Records to the server**********************/
    const onArchiveSave = () => {

        /******Handle all showing the loading progress*****/
        var successStatus = true;
        interface SaveLoadingProgressInterface {
            attendance : boolean;
            homework : boolean;
            quiz : boolean;
            exam : boolean;
        }
        const loadingProgress : SaveLoadingProgressInterface = {
            attendance: false,
            homework: false,
            quiz: false,
            exam: false
        }

        const isLoading = () => reduce<keyof SaveLoadingProgressInterface, boolean>(Object.keys(loadingProgress) as Array<keyof SaveLoadingProgressInterface>, false, (acc : boolean,  elem : keyof SaveLoadingProgressInterface) => {
            return acc || !loadingProgress[elem];
        });
        const handleError = (errors : any) => {
            successStatus = false;
            logAllErrors(errors);
        }
        const ifLoadingDone = (key : keyof SaveLoadingProgressInterface) => () => {
            loadingProgress[key] = true;
            if(!isLoading()) {
                coreContext.setLoading(false);
                if(successStatus)
                    logSuccess('All Records were updated successfully');
            }
        }

        /****Async send requests to the server*****/
        coreContext.setLoading(true)
        ArchiveService.syncAttendances(
            archive.attendances
            .filter((entity) => entity.dirty)
        )
        .then()
        .catch(handleError)
        .finally(ifLoadingDone('attendance'));

        ArchiveService.syncHomeworks(
            archive.homeworks
            .filter((entity) => entity.dirty)
        )
        .then()
        .catch(handleError)
        .finally(ifLoadingDone('homework'));

        ArchiveService.syncExams(
            archive.exams
            .filter((entity) => entity.dirty)
        )
        .then()
        .catch(handleError)
        .finally(ifLoadingDone('exam'));

        ArchiveService.syncQuizzes(
            archive.quizzes
            .filter((entity) => entity.dirty)
        )
        .then()
        .catch(handleError)
        .finally(ifLoadingDone('quiz'));
        
    }

    /***********Search Functionality*************/
    const onSearch = (keyword : string) => {
        if(keyword === '' || !keyword) setArchive({...originalArchive});
        keyword = keyword.toLowerCase();
        setArchive({
            attendances: originalArchive.attendances.filter(a => a.toString().toLowerCase().includes(keyword)),
            homeworks: originalArchive.homeworks.filter(h => h.toString().toLowerCase().includes(keyword)),
            quizzes: originalArchive.quizzes.filter(q => q.toString().toLowerCase().includes(keyword)),
            exams: originalArchive.exams.filter(e => e.toString().toLowerCase().includes(keyword))
        })
    }

    /******* Render Helpers *********/
    const renderTabs = () => {
        return (
                <AppBar position="static" color="transparent">
                    <Tabs value={tabIndex} onChange={onChangeTab} aria-label="simple tabs example">
                    {archiveTabs.map((val, index) => 
                        <Tab key={val +'tab' + index} label={val} {...a11yProps(index)} />
                    )}
                    </Tabs>
                    <div className="archive-editor-container">
                        <EntityList recordInputComponent={archiveRecordInputComponent[tabIndex]} recordFactory={archiveRecordFactory[tabIndex]} allEnrolledStudents={selectedClass?.students || []} selectedClass={selectedClass || nilClass} updateEntityInArray={updateEntityInList} entityType={archiveTabs[tabIndex]} entityObjects={archive[archiveKeys[tabIndex]]} onEntityDelete={removeEntityFromList} updateServiceFunc={updateEntityServiceFunc[tabIndex]}/>
                    </div>
                </AppBar>
        );
    }

    const renderSelectedClassEntities = () => {
        if(!selectedClass) return (null);
        return (
            <div className="archive-editor">
                <div className="archive-toolbar">
                    <div className="archive-searchbar">
                        <SearchBarComponent onChange={(e) => onSearch(e.target.value)}/>
                    </div>
                    <div className="add-student-button">
                        <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<Add />}
                                onClick={onAddOpen}
                                style={{
                                    width: '15%',
                                    marginRight: '70%'
                                }}
                            >
                            {archiveTabs[tabIndex]}
                        </Button>
                        <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<Save />}
                                onClick={onArchiveSave}
                                style={{
                                    width: '15%',
                                    backgroundColor: 'green'
                                }}
                            >
                            Save
                        </Button>
                    </div>
                </div>
                {renderTabs()}
            </div>
        )
    }

    /********Render*********/
    return (
        <div className="list-container">
            <h2>Please Select a Class </h2>
            <OutlinedSelect  label="Choose Class" items={classes.map(c => c.className)} values={classes.map(c=> c.id)} optional={true} onChange={onSelectedClassChange} value={selectedClass?.id || 0}/>
            {renderSelectedClassEntities()}
            <ModalComponent closeModal={onAddClose} isOpen={isAddOpen}><ManageEntityPage onClose={onAddClose} class_={selectedClass || nilClass} onSaveSuccess={onAddSaveSuccess} operationType={'Add'} entityType={archiveTabs[tabIndex]} entityObject={createNilEntity[tabIndex](selectedClass || nilClass, autoGenerateNewEntityName(singularEntityNames[tabIndex], archive[archiveKeys[tabIndex]]))} serviceFunc={createEntityServiceFunc[tabIndex]}/></ModalComponent>

        </div>
    );
}

export default ArchivePage;