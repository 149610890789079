
import React from 'react';
import { NavBarItemModel } from '../../../models/navbar-item';
import Archive from '@mui/icons-material/Archive';
import Notifications from '@mui/icons-material/Notifications';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import School from '@mui/icons-material/School';
import Person from '@mui/icons-material/Person';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ShowChart from '@mui/icons-material/ShowChart';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InsertChart from '@mui/icons-material/InsertChart';
import Class from '@mui/icons-material/Class';
import Mail from '@mui/icons-material/Mail';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import './side-navbar.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LayoutService from '../../../services/layout.service';
import {logAllErrors} from '../../../utils/utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    openedListitem: {
       backgroundColor: '#f4f4f4' 
    },
    closedListItem: {
        backgroundColor: 'white'
    },
    nested: {
      paddingLeft: theme.spacing(5),
      backgroundColor: '#f4f4f4'
    },
  }),
);

/**
 * A helper that initializes the navigation bar items array
 * @returns an array of NavBarItemModels that should be rendered
 *          on the navigation bar
 */
 async function initializeNavBarItems() : Promise<Array<NavBarItemModel>>{
    var archiveItems = (await LayoutService.fetchAllClassCategory()).map(str => new NavBarItemModel(School, str, "/archive/" + str, []));
    var reportsItems = [new NavBarItemModel(ShowChart, 'Class Reports', "/ecr", []), new NavBarItemModel(InsertChart, 'Student Reports', "/esr", [])]
    return [
        new NavBarItemModel(Archive, 'Archive', '#', archiveItems),
        new NavBarItemModel(InsertChart, 'Reports', '#', reportsItems),
        new NavBarItemModel(AutoGraphIcon, 'Performance', '/performance', []),
        new NavBarItemModel(FormatListNumberedIcon, 'Students Ranking', '/ranking', []),
        new NavBarItemModel(Mail, 'Compose Email', "/compose" , []),
        new NavBarItemModel(Notifications, 'Send a Notification', '/send-notification', []),
        new NavBarItemModel(PictureAsPdf, 'Presentations', "#", []),
        new NavBarItemModel(Person, 'Student Accounts', "/students", []),
        new NavBarItemModel(SupervisorAccountIcon, 'Manage TAs', '/teachers', []),
        new NavBarItemModel(Class, 'Manage Classes', "/classes", []),
    ];
}

/**
 * A components that renders a single navbar item. It takes as props an object of type NavBarItemModel
 * and returns the HTML code rendering of the this item. There are two cases: 
 * 1. The NavBarItemModel object has subitems 
 * 2. The NavBarITemModel object does not have subitem 
 * 
 * In the first case, an expand icon is added to the rendered List item and clicking on it just 
 * expands the sublist of items. The subitems are recursively rendered as sub items.
 * 
 * In the latter case, nothing is added. And clicking on it navigates to a certain page.
 * 
 * @props param navBarItem : an object of type navBarItemModel
 * @props param isGrayBackgroundColor: a boolean, set to true to make background color of the list gray.
 *        otherwise the background is white
 * @returns the rendered ListItem HTML
 */
interface props { navBarItem : NavBarItemModel, isGrayBackgroundColor: boolean };
function NavBarItemComponent(props : props) {
    const classes = useStyles();
    const history = useHistory();
    const divRef = React.createRef<HTMLDivElement>();
    const listRef = React.createRef<HTMLDivElement>();
    const listItemRef = React.createRef<HTMLDivElement>();
    const [open, setOpen] = React.useState(props.isGrayBackgroundColor);

    
    const hasSubList = () => {
        return props.navBarItem.subItems.length > 0;
    }
    const toggleSubList = () => {
      setOpen(!open);
    };
    const navigate = () => {
        history.push(props.navBarItem.link)
    };
    
    const handleClick = hasSubList() ? toggleSubList : navigate;
    var expandIcon = null;
    if(hasSubList()) {
        expandIcon = open ? <ExpandLess/>: <ExpandMore/>;
    }
    
    return (
        <div ref={divRef}>
            <ListItem ref={listItemRef} button onClick={handleClick} className={open ? classes.openedListitem : classes.closedListItem}>
                <ListItemIcon>
                    <props.navBarItem.icon/>
                </ListItemIcon>
                <ListItemText primary={props.navBarItem.text} />
                {
                    expandIcon
                }
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List ref={listRef} component="div" className={classes.nested} disablePadding>
                    {props.navBarItem.subItems.map((value, index) => <NavBarItemComponent navBarItem={value} isGrayBackgroundColor={true} key={index}/>)}
                </List>
            </Collapse>
    </div>
    );
}
/**
 * Side Navigation Bar Contents
 */
export default function SideNavBar() {

    const [navBarItems, setNavBarItems] = React.useState<Array<NavBarItemModel>>([]);

    React.useEffect(() => {
        initializeNavBarItems()
            .then(items => setNavBarItems(items))
            .catch(err => logAllErrors(err));
    }, [])
    
    const classes = useStyles();

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {navBarItems.map((value : NavBarItemModel, index : number) => {
                return (<NavBarItemComponent navBarItem={value} isGrayBackgroundColor={false} key={index}/>)
            })}
        </List>
    ) 
}