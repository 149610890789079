import React, {useContext, useState} from 'react';
import Class from '@mui/icons-material/Class';
import Add from '@mui/icons-material/Add';
import Button from '@material-ui/core/Button';
import CoreContext from '../../../state/contexts/core-context';
import ClassesService from  '../../../services/classes.services';
import RankingService from  '../../../services/ranking.service';
import UserService from  '../../../services/users.service';
import { logAllErrors } from '../../../utils/utils';
import { ClassCategoryModel, ClassModel, nilClass } from '../../../models/classes';
import Delete from '@mui/icons-material/Delete';
import ModalComponent from '../../../basic-components/modal/modal.component';
import EditClassPage from './edit-class/edit-class';
import { UserModel } from '../../../models/users';
import { reduce } from '../../../utils/utils';
import Whatshot from '@mui/icons-material/Whatshot';
import AcUnit from '@mui/icons-material/AcUnit';
import CreateClassPage from './create-class/create-class';
import './classes.css';
import SearchBarComponent from '../../../basic-components/searchbar/searchbar.component';

interface LoadingProgress {
    classes : boolean;
    classCategories : boolean;
    teachers: boolean;
    students: boolean;
}
function ClassesPage() {

    /***General State***/
    const coreContext = useContext(CoreContext); 
    const [loaded, setLoaded] = useState<boolean>(false);
    const [allclasses, setAllClasses] = useState<Array<ClassModel>>([]);
    const [classes, setClasses] = useState<Array<ClassModel>>([]);
    const [allClassCategories, setAllClassCategories] = useState<Array<ClassCategoryModel>>([]);
    const [allTeachers, setAllTeachers] = useState<Array<UserModel>>([]);
    const [allStudents, setAllStudents] = useState<Array<UserModel>>([]);

    const serverDataSettersCollection = {
        'classes': setClasses,
        'classCategories': setAllClassCategories,
        'teachers': setAllTeachers,
        'students': setAllStudents
    }
    /*****Page Initialization*****/
    React.useEffect(() => {
        // this prevents the component of being rendered recursively 
        // every time coreContext.setLoading is called
        if(loaded) return;
        setLoaded(true);

        const loadingProgress : LoadingProgress = {
            'classes': false,
            'classCategories': false,
            'teachers': false,
            'students': false,
        };

        const loadingStatus = () => reduce<keyof LoadingProgress, boolean>(Object.keys(loadingProgress) as Array<keyof LoadingProgress>, false, (acc : boolean,  elem : keyof LoadingProgress) => {
            return acc || !loadingProgress[elem];
        } );

        const handleFetchedData = (key : keyof LoadingProgress, data :any ) => {
            loadingProgress[key] = true;
            serverDataSettersCollection[key](data);
        }
        const stopLoadingIfAllDone = () => coreContext.setLoading(loadingStatus()); 

        coreContext.setLoading(true);
        ClassesService.fetchAllClasses().then((classes) => {
            handleFetchedData('classes', classes);
            setAllClasses(classes.slice(0, classes.length));
        }).catch(err => {
            console.log(err);
            logAllErrors(err);
        }).finally(stopLoadingIfAllDone);

        ClassesService.fetchAllClassCategories().then(classCategories => {
            handleFetchedData('classCategories', classCategories);
        }).catch(err => {
            console.error(err);
            logAllErrors(err);
        }).finally(stopLoadingIfAllDone);

        UserService.fetchAllTeacher().then(teachers => {
            handleFetchedData('teachers', teachers);
        }).catch(err => {
            console.error(err);
            logAllErrors(err);
        }).finally(stopLoadingIfAllDone);
        
        UserService.fetchAllStudents().then(students => {
            handleFetchedData('students', students);
        }).catch(err => {
            console.log(err);
            logAllErrors('Error Loading The students');
        }).finally(stopLoadingIfAllDone);

        // eslint-disable-next-line
    }, [])
    
    /*******Seach callback*******/
    const onSearch = (needle : string) => {
        needle = needle.toLowerCase()
        if(needle === '') setClasses([...allclasses]);
        else
            setClasses(allclasses.filter(class_ => 
                class_.className.toLowerCase().includes(needle) ||
                class_.classCategory.name.toLowerCase().includes(needle)
                ));
    }
    /****Manage Classes****/
    const onClassDelete = (class_ : ClassModel) => {
        if(!window.confirm('Are you sure you want to delete this class: ' + class_.className)) return;
    
        coreContext.setLoading(true);
        ClassesService.deleteClass(class_).then(() => {
            classes.splice(classes.indexOf(class_), 1);
            setClasses(classes);
        }).catch(err => {
            logAllErrors('Could not Delete class');
            console.log(err);
        }).then(() => coreContext.setLoading(false));
    }

    /*******Modal State and Function*********/
    const [isEditOpened, setIsEditOpened] = useState<boolean>(false);
    const [selectedClass, setSelectedClass] = useState<ClassModel>(nilClass);
    const onEditOpen = (class_ : ClassModel) => {
        setSelectedClass(class_);
        setIsEditOpened(true);
    }
    const onEditClose = () => {
        setIsEditOpened(false);
    }
    
    const onEditSave = (class_ : ClassModel) => {
        setClasses(classes.map(c => class_.id === c.id ? class_ : c));
    }

    /**Create class**/
    const [isCreateOpened, setIscreateOpened] = useState<boolean>(false);
    const openAddNewClass = () => {
        setIscreateOpened(true);
    }
    const onCreateClose = () => {
        setIscreateOpened(false);
    }

    const onCreateSave = (class_  : ClassModel) => {
        classes.push(class_);
        setClasses(classes);
    }

    const onRankingToggle = async (class_: ClassModel) => {
        const toggledClass: ClassModel = {
            ...class_, 
            isRanked: !class_.isRanked,
            lastSessionDate: class_.lastSessionDate
        }
        coreContext.setLoading(true);

        try {
            await RankingService.updateIsRankedState(toggledClass)
            setClasses(classes.map((val) => {
                if(val.id === class_.id) {
                    return toggledClass
                }
                else {
                    return val
                }
            }))
            setAllClasses(allclasses.map((val) => {
                if(val.id === class_.id) {
                    return toggledClass
                }
                else {
                    return val
                }
            }))
        } catch(err) {
            logAllErrors(err)
        }
        finally {
            coreContext.setLoading(false)
        }

    }

    /*******Render Helpers**************/
    const renderClass = (class_ : ClassModel, index : number) => {
        return (
            <tr onClick={() => {onEditOpen(class_)}} key={"tr"+index}>
                <td>{ index + 1 }</td>
                <td>{ class_.className}</td>
                <td>{ class_.classCategory.name}</td>
                <td>{ class_.fees || '____' }</td>
                <td>{ class_.startDate.toLocaleDateString() }</td>
                <td>{ class_.endDate.toLocaleDateString() }</td>
                <td>{ class_.sessions.length }</td>
                <td onClick={(e) => { e.stopPropagation(); onRankingToggle(class_)}}>{ class_.isRanked ? <Whatshot/> : <AcUnit/> }</td>
                <td onClick={(e) => { e.stopPropagation(); onClassDelete(class_)}}><Delete color="secondary"/></td>
            </tr>
        );
    }
    const renderClasses = ()  => {
         return classes.map(renderClass);
    }

    
    /****Render****/
    return (
        <div className="list-container">
            <ModalComponent closeModal={onEditClose} isOpen={isEditOpened}><EditClassPage title="Edit Class" serviceFunc={ClassesService.updateClass} classCategories={allClassCategories} onClose={onEditClose} onSave={onEditSave} teachers={allTeachers} students={allStudents} class_={selectedClass}/></ModalComponent>
            <ModalComponent closeModal={onCreateClose} isOpen={isCreateOpened}><CreateClassPage title="Create new Class" classCategories={allClassCategories} onClose={onCreateClose} onSave={onCreateSave} teachers={allTeachers}/></ModalComponent>
            <div className="page-header">
                <h2 className="mytitle">Manage Classes</h2>
            </div>
            <div className="class-searchbar">
                <SearchBarComponent onChange={(e) => onSearch(e.target.value)}/>
            </div>
            <div className="classes-table-container">
                <table className="students-table">
                    <thead>
                        <tr>
                            <th><Class/></th>
                            <th>List of Classes</th>
                            <th>Class Category</th>
                            <th>fees</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Sessions/Week</th>
                            <th>Ranking State</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderClasses()}
                    </tbody>
                </table>
            </div>
            <div className="add-student-button">
                <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<Add />}
                        onClick={() => openAddNewClass()}
                    >
                        Add
                </Button>
            </div>
        </div>
    )
}

export default ClassesPage;