import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import  SideNavBar from './side-navbar/side-navbar';
import useStyles from './styles';
import './layout.css'


export default function Layout(props : any) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                [classes.hide]: open,
                })}
            >
            <MenuIcon />
            </IconButton>
            <img className="Logo-img" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/header.png?alt=media&token=e15307c2-2c69-4d71-aa6a-c822b6bd4cf5" alt="logo"/>
            <Typography variant="h5" className="Title">
                Mina Adly
            </Typography>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={logout}
            >
            <ExitToAppIcon />
            </IconButton>
        </Toolbar>

      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <SideNavBar></SideNavBar>
        <Divider />
      </Drawer>
      <div className={classes.pageContainer}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
              {props.children}
        </main>
      </div>
    </div>
  );
}
