import React from 'react';
import { ECRModel } from '../../../../models/ecr';
import './ecr-template.css'
interface ECRTemplateComponentProps {
    children : any;
    ecr : ECRModel;
}

const weekdaysAccordingToJS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function ECRTemplateComponent(props : ECRTemplateComponentProps) {
    return (
        <div className="ecr-template-inner-container">
            <div className="ecr-template-header">
                <img className = "ecr-template-logo" src="https://firebasestorage.googleapis.com/v0/b/minaportal.appspot.com/o/header.png?alt=media&amp;token=ebd2af4b-fa08-4f89-b4f5-63b792d92268" height="85%" alt="logo"></img>
            </div>
            <div className="ecr-template-body">
                <p><h3>Dear student's parents,</h3></p>
                <p>Kindly find the progress report of <strong>{props.ecr.date.toLocaleDateString('en-UK')}</strong></p>
                <div className="ecr_template_main_points">
                    <table style={{width: '40%'}}>
                        <tr>
                            <td>Class Name:</td>
                            <td>{props.ecr.class_.className}</td>
                        </tr>
                        <tr>
                            <td>Session Of:</td>
                            <td>{weekdaysAccordingToJS[props.ecr.date.getDay()]}</td>
                        </tr>
                        <tr>
                            <td>Attendance:</td>
                            <td>Attended/Not Attended</td>
                        </tr>
                        <tr>
                            <td>Homework:</td>
                            <td>Done/Not Done</td>
                        </tr>
                        <tr>
                            <td>Quiz:</td>
                            <td>0-15</td>
                        </tr>
                        <tr>
                            <td>Exam:</td>
                            <td>0-100</td>
                        </tr>
                    </table>
                </div>
                <div className="ecr-template-extra-body">
                    <p>{props.children}</p>
                </div>
                <div className="ecr-template-regards">
                    <p><h3 className='ecr-template-h6'>Kind Regards,</h3></p>
                    <p><h3 className='ecr-template-h6'>Dr. Mina Adly</h3></p>
                </div>
            </div>
            <div className="ecr-template-footer">
            </div>
        </div>
    );
}
