import { ClassModel, nilClass } from "./classes";

export class ClassNotification {
    class_ : ClassModel = nilClass;
    title : string = '';
    body : string = '';

    serialize() {
        return {class_: this.class_.id, title: this.title, body: this.body};
    }
}