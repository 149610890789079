import { Button, TextField } from '@material-ui/core';
import { Close, Save } from '@mui/icons-material';
import React, { useState } from 'react';
import OutlinedDatePicker from '../../../../basic-components/outlined-datepicker/outlined-datepicker';
import { EntityModel, ArchiveRecordModel } from '../../../../models/archive';
import { ClassModel } from '../../../../models/classes';
import { logAllErrors, logSuccess } from '../../../../utils/utils';
import './manage-entity.css';


interface ManageEntityProps {
    operationType : 'Add' | 'Edit';
    entityType : 'Attendance' | 'Homework' | 'Quizzes' | 'Exams';
    entityObject : EntityModel<ArchiveRecordModel>;
    class_ : ClassModel;
    serviceFunc : (_ : EntityModel<ArchiveRecordModel>) => Promise<EntityModel<ArchiveRecordModel>>;
    onClose : () => void;
    onSaveSuccess : ( _ :EntityModel<ArchiveRecordModel>) => void;
}

export default function ManageEntityPage(props : ManageEntityProps) {

    const [entityObject, setEntityObject] = useState<EntityModel<ArchiveRecordModel>>(props.entityObject);
    const onSave = () => {
        props.serviceFunc(entityObject).then((res) => {
            logSuccess(props.operationType + 'ed ' + entityObject.name + ' successfully!');
            props.onSaveSuccess(res);
            props.onClose();
        }).catch(err => logAllErrors(err));
    }

    const onAttrChange = (key : keyof EntityModel<ArchiveRecordModel>, val : string | Date) => {
        setEntityObject({
            ...entityObject,
            [key]: val,
            insertIntoArchive: entityObject.insertIntoArchive,
            removeFromArchive: entityObject.removeFromArchive,
            updateEntityInArchive: entityObject.updateEntityInArchive,
            toggleViewMyRecords: entityObject.toggleViewMyRecords
        });
    }
    return (
        <div className="manage-entity-container">
            <h1>{props.operationType + ' ' + props.entityType}</h1>
            <div className="manage-entity-form">
                <TextField error={entityObject.name === ''} id="outlined-basic" label={props.entityType + ' Name'} variant="outlined" value={entityObject.name} onChange={(event) => onAttrChange('name', event.target.value)} fullWidth/>
                <OutlinedDatePicker label={props.entityType + ' Date'} onChange={(date) => onAttrChange('date', date || entityObject.date)} value={entityObject.date}/>
                <div className="manage-entity-buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<Save />}
                        onClick={onSave}
                    >
                        Save
                    </Button>
                    <div className="manage-entity-buttons-separator"></div>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<Close />}
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

