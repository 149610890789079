import settings from '../settings.json';
import Axios from 'axios';
import { setAuthOnAxios } from './auth.service';
import { ClassModel } from '../models/classes';
import { ArchiveModel, ArchiveRecordModel, AttendanceModel, EntityModel, ExamModel, HomeworkModel, QuizModel, serializeArchiveEntityModel } from '../models/archive';


setAuthOnAxios(Axios);



const ArchiveService = {

    fetchAllArchiveEntities: async function(class_ : ClassModel) {
        var entities : any = {
            quiz: [],
            exam: [],
            homework: [],
            attendance: []
        }
        try{ 

            for(const key in entities) {
                const url = settings.baseUrl + 'api/archive/' + key + '?class_id=' + class_.id;
                const res = await Axios.get(url);
                entities[key] = res.data;
            }
            console.log(entities)
            return new ArchiveModel(entities);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },

    createNewQuiz : async function(quiz : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/quiz'

        try {
            const res = await Axios.post(url, serializeArchiveEntityModel(quiz));
            return new QuizModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    updateQuiz : async function(quiz : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/quiz/' + quiz.id

        try {
            const res = await Axios.put(url, serializeArchiveEntityModel(quiz));
            return new QuizModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    createNewExam : async function(exam : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/exam'

        try {
            const res = await Axios.post(url, serializeArchiveEntityModel(exam));
            return new ExamModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    updateExam : async function(exam : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/exam/' + exam.id;

        try {
            const res = await Axios.put(url, serializeArchiveEntityModel(exam));
            return new ExamModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    createNewAttendance : async function(attendance : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/attendance'

        try {
            const res = await Axios.post(url, serializeArchiveEntityModel(attendance));
            return new AttendanceModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    updateAttendance : async function(attendance : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/attendance/' + attendance.id;

        try {
            const res = await Axios.put(url, serializeArchiveEntityModel(attendance));
            return new AttendanceModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    createNewHomework : async function(homework : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/homework'

        try {
            const res = await Axios.post(url, serializeArchiveEntityModel(homework));
            return new HomeworkModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    updateHomework : async function(homework : EntityModel<ArchiveRecordModel> ) : Promise<EntityModel<ArchiveRecordModel>> {
        const url = settings.baseUrl + 'api/archive/homework/' + homework.id;

        try {
            const res = await Axios.put(url, serializeArchiveEntityModel(homework));
            return new HomeworkModel(res.data);
        } catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    deleteArchiveEntity : async function(entity : EntityModel<ArchiveRecordModel>) : Promise<void> {
        const url = settings.baseUrl + 'api/archive/archive-entity/' + entity.id;
        try {
            await Axios.delete(url);
        }
        catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    syncAttendances : async function(entities : Array<AttendanceModel>) : Promise<void> {
        if(entities.length === 0) return;
        const url = settings.baseUrl + 'api/archive/attendance-record-sync';
        const body = entities.map(entity => entity.serialize());
        try {
            await Axios.post(url, body);
        }catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    syncHomeworks : async function(entities : Array<HomeworkModel>) : Promise<void> {
        if(entities.length === 0) return;
        const url = settings.baseUrl + 'api/archive/homework-record-sync';
        const body = entities.map(entity => entity.serialize());
        try {
            await Axios.post(url, body);
        }catch(err) {
            console.error(err);
            throw err.response?.data;
        };
    },
    syncQuizzes : async function(entities : Array<QuizModel>) : Promise<void> {
        if(entities.length === 0) return;
        const url = settings.baseUrl + 'api/archive/quiz-record-sync';
        const body = entities.map(entity => entity.serialize());
        try {
            await Axios.post(url, body);
        }catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    },
    syncExams : async function(entities : Array<ExamModel>) : Promise<void> {
        if(entities.length === 0) return;
        const url = settings.baseUrl + 'api/archive/exam-record-sync';
        const body = entities.map(entity => entity.serialize());
        try {
            await Axios.post(url, body);
        }catch(err) {
            console.error(err);
            throw err.response?.data;
        }
    }
}

export default ArchiveService;