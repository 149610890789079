import React, {useState} from 'react';
import './edit-class.css';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SaveIcon from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import { ClassCategoryModel, ClassModel, nilClass } from '../../../../models/classes';
import { StudentModel, UserModel } from '../../../../models/users';
import {purpleColor} from '../../../../constants';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ClassInfoComponent from '../component/class-info/class-info';
import SessionsComponent from '../component/sessions/sessions';
import TeachersComponent from '../component/teachers/teachers';
import StudentsComponent from '../component/students/students';
import ClassService from '../../../../services/classes.services';
import { logAllErrors, logSuccess } from '../../../../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);


interface EditPageProps {
    title : string;
    class_ : ClassModel ;
    onSave : (_ : ClassModel) => void;
    classCategories : Array<ClassCategoryModel>;
    teachers : Array<UserModel>;
    students : Array<StudentModel>;
    onClose : () => void;
    serviceFunc : (_ : ClassModel) => Promise<ClassModel>;
};

function EditClassPage(props : EditPageProps) {
    const classes = useStyles();

    const [class_, setClass] = useState<ClassModel>({
        id: props.class_.id,
        className: props.class_.className,
        classCategory: props.class_.classCategory,
        students: props.class_.students.slice(0, props.class_.students.length),
        teachers: props.class_.teachers.slice(0, props.class_.teachers.length),
        fees: props.class_.fees,
        sessions: props.class_.sessions.slice(0, props.class_.sessions.length),
        startDate: props.class_.startDate,
        endDate: props.class_.endDate,
        lastSessionDate: nilClass.lastSessionDate,
        isRanked: true
    });




    /******Render Helpers************/
    const [loading, setLoading] = useState<boolean>(false);
    const renderLoader = () => {
        if(loading)
            return (
                <div className="spinner-container"> 
                    <ScaleLoader color={purpleColor}/>
                </div>
            );
        return (null);
    }

    /**Button Clicks Handler**/
    const onSave = () => {
        setLoading(true);
        ClassService.updateClass(class_).then((class_) => {

            props.onSave(class_);
            props.onClose();
            logSuccess('Class Updated Successfully');
            
        }).catch(err => {
            console.log(err);
            logAllErrors('Could not update the class');
        }).finally(() => setLoading(false));
    }


    /******Render******/
    return (
        <div className="student-page-container">
            <div className="student-edit-page-title">
                <h2>{props.title}</h2>
            </div>
            <div className="form-container">
                <ClassInfoComponent class_={class_} setClass={setClass} classCategories={props.classCategories} />
            </div>
            <div className="form-container">
                <SessionsComponent class_={class_} setClass={setClass}/>
            </div>
            <div className="form-container">
                <TeachersComponent allTeachers={props.teachers} class_={class_} setClass={setClass} />
            </div>
            <div className='form-container'>
               <StudentsComponent allStudents={props.students} class_={class_} setClass={setClass} setGlobalClass={props.onSave}/>
            </div>
            <div className="edit-student-submit">
                <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={onSave}
                >
                    Save
                </Button>
                <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    startIcon={<Close />}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </div>
            {renderLoader()}
        </div>
    )
}

export default EditClassPage;